import { Action, ActionCreator, getType, PayloadAction } from "typesafe-actions";
import { FailedEntry, RequestEntry, SucceededEntry } from "../../../store/shared/request/types";
import { FailurePayload } from "../../../middleware/model";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { AsyncLoadableActionCreator } from "../../../store/store-util";
import { useRequestHistoryForDispatchId } from "../../../store/shared/request/selectors";
import { convertToArray } from "../../../utility-functions";

export const usePrevious = <T extends any>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

export type AsyncWatcherStatus = {
    isFirstLoad: true,
    ongoing: false,
    failed: false
} | {
    isFirstLoad: boolean,
    ongoing: true,
    failed: false
} | {
    isFirstLoad: false,
    ongoing: false,
    failed: false
} | {
    isFirstLoad: false,
    ongoing: boolean,
    failed: true,
    error: FailurePayload<any, any>
}

export type WatchableTarget = Action | ActionCreator | AsyncLoadableActionCreator<any, any, any> | (Action | ActionCreator | AsyncLoadableActionCreator<any, any, any>)[];

const matchesTarget = (request: RequestEntry, target: Action | ActionCreator | AsyncLoadableActionCreator<any, any, any>) => {
    const actionType = (target as Action).type || getType((target as AsyncLoadableActionCreator<any, any, any>).request || target);
    const payload = (target as PayloadAction<any, any>).payload;
    return actionType === request.type && (!payload || payloadMatches(payload, request.payload));
};

const payloadMatches = (payload1: any, payload2: any) =>
    typeof payload1 === "string" ? payload1 === payload2 :
        (!payload1.id || (payload1.id === payload2.id));

export const useTypedAsyncWatcher = <REQ, RESP, ERR>(target?: AsyncLoadableActionCreator<REQ, RESP, ERR> | AsyncLoadableActionCreator<REQ, RESP, ERR>[], handlers?: { onSuccess?: (responsePayload: RESP, requestPayload: REQ) => void, onEvent?: (event: AsyncWatcherStatus) => void }) => {
    return useAsyncWatcher(convertToArray(target).map(it => it.request), handlers);
};

export const useAsyncWatcher = (
    target?: WatchableTarget,
    handlers?: { onFailure?: (payload: any) => void, onSuccess?: (responsePayload: any, requestPayload: any) => void, onEvent?: (event: AsyncWatcherStatus) => void },
    dispatchId?: string
): AsyncWatcherStatus | undefined => {

    const [watcherStatus, setWatcherStatus] = useState<AsyncWatcherStatus | undefined>();
    const [ongoingRequests, setOngoingRequests] = useState<RequestEntry[]>([]);
    const [initialLoadSucceeded, setInitialLoadSucceeded] = useState<boolean>(false);
    const {storeSharedRequest, prevStoreSharedRequest} = useRequestHistoryForDispatchId(dispatchId);

    useLayoutEffect(() => {
        if (!target || !prevStoreSharedRequest || prevStoreSharedRequest.ongoing.length === storeSharedRequest.ongoing.length) {
            return;
        }

        const isRequestInScope = (request: RequestEntry) => convertToArray(target).find(target => matchesTarget(request, target));
        const ongoingIds = ongoingRequests.map(req => req.id);
        const finishedIds = ongoingIds.filter(id => storeSharedRequest.history[id]?.status !== "ONGOING");
        const newRequests = storeSharedRequest.ongoing
            .filter(id => !(prevStoreSharedRequest?.ongoing.includes(id)))
            .map(id => storeSharedRequest.history[id])
            .filter(req => !!req)
            .filter(isRequestInScope);

        if (newRequests.length || finishedIds.length) {
            const updatedOngoingRequests = ongoingRequests
                .filter(req => !finishedIds.includes(req.id))
                .concat(newRequests);
            const finishedRequests = finishedIds.map(id => storeSharedRequest.history[id]).filter(req => !!req);
            const failedRequests: FailedEntry[] = finishedRequests.filter(req => req.status === "FAILURE") as FailedEntry[];
            let updatedStatus: AsyncWatcherStatus;
            if (failedRequests.length > 0) {
                const failedEntry = failedRequests[0];
                handlers?.onFailure?.(failedEntry.error);
                updatedStatus = {
                    isFirstLoad: false,
                    ongoing: updatedOngoingRequests.length > 0,
                    failed: true,
                    error: failedEntry.error
                };
            } else if (updatedOngoingRequests.length === 0) {
                const successEntry = finishedRequests[0];
                handlers?.onSuccess?.((finishedRequests[0] as SucceededEntry).result?.data, successEntry.payload);
                updatedStatus = {
                    isFirstLoad: false,
                    ongoing: false,
                    failed: false
                };
                setInitialLoadSucceeded(true);

            } else {
                //hack to not show a spinner when opening the filter box
                updatedStatus = {
                    isFirstLoad: false,
                    ongoing: false,
                    failed: false
                };

                // updatedStatus = {
                //     isFirstLoad: !initialLoadSucceeded,
                //     ongoing: true,
                //     failed: false
                // };
            }
            handlers?.onEvent?.(updatedStatus);
            setWatcherStatus(updatedStatus);
            setOngoingRequests(updatedOngoingRequests);
        }
    }, [target, initialLoadSucceeded, handlers, ongoingRequests, storeSharedRequest, prevStoreSharedRequest]);

    return watcherStatus;
};
