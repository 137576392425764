import { get } from "./service-util";
import { PageRequest, PageResponse } from "../model";
import { BaseRetirement, Credit, Retirement, Sink, BaseSink, BaseCredit } from "../../store/registry/types";

const BASE_PATH: string = "/api/v1/public/Registry";

export class RegistryApi {
    findSinks = (pageRequest: PageRequest) => get<PageResponse<BaseSink>>(BASE_PATH + "/sink", pageRequest);
    findSink = (id: string) => get<Sink>(BASE_PATH + "/sink/" + id);
    findCredits = (pageRequest: PageRequest) => get<PageResponse<BaseCredit>>(BASE_PATH + "/credit", pageRequest);
    findCredit = (id: string) => get<Credit>(BASE_PATH + "/credit/" + id);
    findRetirements = (pageRequest: PageRequest) => get<PageResponse<BaseRetirement>>(BASE_PATH + "/retirement", pageRequest);
    findRetirement = (id: string) => get<Retirement>(BASE_PATH + "/retirement/" + id);
}

export const RegistryService = new RegistryApi();
