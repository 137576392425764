import { DetailCard } from "../../components/detail-card/DetailCard";
import React from "react";
import { Summary } from "../../components/Summaries";
import { timeTemplate } from "../../components/master-detail/Templates";
import { DetailViewInputParams } from "../../components/master-detail/DetailView";
import { NavLink } from "react-router-dom";
import { Retirement } from "../../../store/registry/types";
import { ExternalLink } from "../../components/link/Links";
import { CreditLink } from "../credit/CreditDetailView";
import { REGISTRY_RETIREMENT_URL } from "../../Root";
import { Persistence, RegistrySinkLink } from "../sink/SinkDetailView";

export const RetirementDetailView = (props: DetailViewInputParams<Retirement>) => {
    const retirement = props.data;
    return <div>
        <DetailCard title={retirement.externalId}
                    headerActions={<ExternalLink href={props.data.externalUrl}/>}>
            <div>
                <div>
                    <Summary
                        entries={[
                            ["Amount", retirement.amountInTons],
                            ["Persistence", <Persistence startYear={retirement.vintage} persistenceInYears={retirement.persistenceInYears}/>],
                            ["Registrar", retirement.credit.sink.registrar],
                            ["Provider", <span>{retirement.credit.provider.companyName}<ExternalLink href={retirement.credit.provider.externalLink} size={"SMALL"}/></span>],
                            ["Retirement Timestamp", timeTemplate(retirement.timestamp)],
                            ["Credit", <CreditLink id={retirement.credit.id} label={retirement.credit.externalId}/>],
                            ["Sink", <RegistrySinkLink id={retirement.credit.sink.id} label={retirement.credit.sink.externalId}/>],
                        ]}
                    />
                </div>
            </div>
        </DetailCard>
    </div>;
};

export const RetirementLink = ({id, label}: { id: string, label: string }) => {
    return <NavLink to={REGISTRY_RETIREMENT_URL + "/view/" + id}>
        <span className={"element__text-link"}>{label}</span></NavLink>;
};
