import { emptyResponse, initialData, PageRequest, PageResponse, SummaryRequest, SummaryResponse } from "../../middleware/model";
import { createLoadableAsyncAction, createReducerForLoadableData } from "../store-util";
import { combineReducers } from "redux";
import { BaseRetirement, Credit, Retirement, Sink, BaseSink, BaseCredit } from "./types";

const NAMESPACE = "registry";

export const RegistryActionCreators = {
    findAllSinks: createLoadableAsyncAction(NAMESPACE, "FIND_ALL_SINKS")<PageRequest, PageResponse<BaseSink>>(),
    summarizeSinks: createLoadableAsyncAction(NAMESPACE, "SINKS_SUMMARY")<SummaryRequest, SummaryResponse | undefined>(),
    findSinkById: createLoadableAsyncAction(NAMESPACE, "FIND_SINK_BY_ID")<string, Sink | undefined>(),
    findAllCredits: createLoadableAsyncAction(NAMESPACE, "FIND_ALL_CREDITS")<PageRequest, PageResponse<BaseCredit>>(),
    findCreditById: createLoadableAsyncAction(NAMESPACE, "FIND_CREDIT_BY_ID")<string, Credit | undefined>(),
    findAllRetirements: createLoadableAsyncAction(NAMESPACE, "FIND_ALL_RETIREMENTS")<PageRequest, PageResponse<BaseRetirement>>(),
    findRetirementById: createLoadableAsyncAction(NAMESPACE, "FIND_RETIREMENT_BY_ID")<string, Retirement | undefined>(),
};

export const registryReducer = combineReducers({
    findAllSinks: createReducerForLoadableData(RegistryActionCreators.findAllSinks, initialData(emptyResponse([]))),
    findSinkById: createReducerForLoadableData(RegistryActionCreators.findSinkById, initialData(undefined)),
    sinkSummaries: createReducerForLoadableData(RegistryActionCreators.summarizeSinks, initialData(undefined)),
    findAllCredits: createReducerForLoadableData(RegistryActionCreators.findAllCredits, initialData(emptyResponse([]))),
    findCreditById: createReducerForLoadableData(RegistryActionCreators.findCreditById, initialData(undefined)),
    findAllRetirements: createReducerForLoadableData(RegistryActionCreators.findAllRetirements, initialData(emptyResponse([]))),
    findRetirementById: createReducerForLoadableData(RegistryActionCreators.findRetirementById, initialData(undefined)),
});
