import React from "react";
import "./Spinner.scss";

interface SpinnerProps {
    size?: number;
}

export const Spinner = (props: SpinnerProps) => <div className="component__spinner">
    <div className="section__balls" style={{width: `${props.size || 30}px`, height: `${(props.size || 30) / 3}px`}}>
        <div className="ball-loader-ball ball1"/>
        <div className="ball-loader-ball ball2"/>
        <div className="ball-loader-ball ball3"/>
    </div>
</div>;
