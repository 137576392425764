import React, { Fragment, useCallback, useMemo } from "react";
import { Button } from "primereact/button";
import "./MightyButton.scss";
import { getExecute, WatchableAction } from "../async-watcher/LoadableDataWatcher";

interface InputProps {
    title?: string;
    label?: string;                                 // The text to show on the button. If not provided, an Icon button is rendered.
    icon?: string;                                  // The icon to show either alone or together with the label. Either label or icon must be provided.
    iconLabel?: string;
    class?: "primary" | "secondary" | "tertiary" | "icon-text" | "warning";
    // The visual class to display the button
    className?: string;                             // Any additional class to add to the element
    disabled?: boolean;                             // Whether to disable the button
    onButtonClick?: (e?: any) => void;                     // The handler that is triggered once the user clicked the button (& all async actions have executed, if necessary)
    action?: WatchableAction<any, any>;
}

export const MightyButtonV2 = (props: InputProps) => {
    const {className, icon, disabled, onButtonClick, iconLabel, action} = props;
    const loading = useMemo(() => !!action?.data.loading ? action.data.loading : false, [action?.data.loading]);
    const iconClass = useMemo(() => loading ? "pi pi-spinner pi-spin" : (icon && ("pi " + icon)), [loading, icon]);

    const onButtonClickWrapper = useCallback(() => {
        if (loading || disabled) {
            return;
        }
        onButtonClick && onButtonClick();
        action && getExecute(action)();
    }, [loading, disabled, action, onButtonClick]);

    return <Fragment>
        {!props.label ?
            <i title={props.title} className={"component__mighty-button icon-only " + (className || "") + " " + ((loading || disabled) ? "disabled " : " ") + iconClass}
               onClick={onButtonClickWrapper}>
                { iconLabel && <span className={"component__mighty-button icon-label"}>{iconLabel}</span> }
            </i>
            : <Button title={props.title}
                      className={"component__mighty-button with-text p-button p-button-" + (props.class || "primary") + " " + (props.className || "")}
                      icon={iconClass}
                      disabled={loading || props.disabled}
                      label={props.label}
                      onClick={onButtonClickWrapper}
                      type="button"
            />
        }
    </Fragment>;
};

