import { useTableLoadActionWithDetail } from "../../components/master-detail/BasePagingMasterDetailView";
import { RootState } from "../../../store/root-reducer";
import { RegistryActionCreators } from "../../../store/registry/store";
import React from "react";
import "./SinkView.scss";
import { timeTemplate, countryTemplate } from "../../components/master-detail/Templates";
import {
    AmountFilterBox,
    DateFilterBox,
    FilterBarComponent,
    FilterComponentProps,
    SelectionFilterBox,
    StringFilterBox
} from "../../components/master-detail/filter/FilterBarComponent";
import { SinkDetailView, Persistence } from "./SinkDetailView";
import { PagingMasterDetailView } from "../../components/master-detail/PagingMasterDetailView";
import { useTranslation } from "react-i18next";
import { countryNamesPerCode } from "../../countries";

const INITIAL_REQUEST = {debounce: 0, size: 5, page: 0, sortField: "certificationTimestamp", sortOrder: -1};
const TABLE_DATA_SELECTOR = (state: RootState) => state.registry.findAllSinks;
const DETAIL_SELECTOR = (state: RootState) => state.registry.findSinkById;

export const SinkView = () => {
    const detailActions = {view: RegistryActionCreators.findSinkById};
    const tableLoadAction = useTableLoadActionWithDetail(INITIAL_REQUEST, RegistryActionCreators.findAllSinks, TABLE_DATA_SELECTOR, DETAIL_SELECTOR);
    const {t} = useTranslation();
    return <div className="view view__registry-sink-view">
        <PagingMasterDetailView
            detailActions={detailActions}
            detailView={SinkDetailView}
            renderFilterBar={FILTER_BAR}
            sortOptions={SORT_OPTIONS}
            tableLoadAction={tableLoadAction}
            title={{render: s => s.externalId}}
            subTitle={{render: s => t("Certification Timestamp") + ": " + timeTemplate(s.certificationTimestamp)}}
            columns={[
                {label: "Registrar", render: sink => sink.registrar},
                {label: "Type", render: sink => t(sink.sinkType)},
                {label: "Amount", render: sink => sink.sequestrationCurve.amount},
                {label: "Issued Amount", render: sink => <span>{sink.issuedAmountInTons}</span>},
                {label: "Retired Amount", render: sink => <span>{sink.retiredAmountInTons}</span>},
                {label: "Persistence", render: sink => <Persistence startYear={sink.vintage} persistenceInYears={sink.persistenceInYears}/>},
                {label: "Country", render: sink => sink.countryCode ? countryTemplate(sink.countryCode) : undefined},
                {label: "Provider", render: sink => sink.provider.companyName},
            ]}
        />
    </div>;
};

const SORT_OPTIONS = [
    {label: "Certification Timestamp", value: "certificationTimestamp"},
    {label: "Registrar", value: "registrar"},
    {label: "Amount", value: "amountInTons"},
    {label: "Issued Amount", value: "issuedAmountInTons"},
    {label: "Retired Amount", value: "retiredAmountInTons"}
];

const FILTER_BAR = (p: FilterComponentProps) => {
    const {i18n} = useTranslation();
    return <FilterBarComponent
        filters={p.filters}
        onFilters={p.onFilters}
        filterBoxes={({
            "FULLTEXT": filterProps => <StringFilterBox {...filterProps} label={"Search"}/>,
            "certificationTimestamp": filterProps => <DateFilterBox {...filterProps} label={"Certification Timestamp"}/>,
            "amountInTons": filterProps => <AmountFilterBox {...filterProps} label={"Amount"}/>,
            "issuedAmountInTons": filterProps => <AmountFilterBox {...filterProps} label={"Issued Amount"}/>,
            "retiredAmountInTons": filterProps => <AmountFilterBox {...filterProps} label={"Retired Amount"}/>,
            "countryCode": filterProps => <SelectionFilterBox {...filterProps} label={"Country"} options={countryNamesPerCode(i18n.language)}/>,
        })}
    />;
};
