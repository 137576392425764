import moment from "moment";
import {formatFixedDecimals, RoundType} from "../FormatUtil";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { getCountryName } from "../../countries";

export const TIME_FORMAT = "D MMM YYYY, HH:mm";
export const timeTemplate = (data: any): string => data ? moment(data).format(TIME_FORMAT) : "";

export const numberTemplate = (type: RoundType, data?: number, factor: number = 1, decimals: number = 4): string => data ? (formatFixedDecimals(data * factor, decimals, type)) : "0";
export const countryTemplate = (countryCode: string) => getUnicodeFlagIcon(countryCode) + " " + getCountryName(countryCode);
