import React, { PropsWithChildren } from "react";
import "./DetailCard.scss";

interface InputProps {
    title?: string | React.ReactNode;
    headerActions?: React.ReactNode;
    actions?: React.ReactNode;
}

export const DetailCard = (props: PropsWithChildren<InputProps>) => {
    return <div className="component__detail-card">
        <div className="section__header">
            <div className="element__caption">{props.title || ""}</div>
            <div className="section__actions">
                {props.headerActions}
            </div>
        </div>
        <div className="section__card-frame">
            <div className="section__content">
                {props.children}
            </div>
            <div className="section__actions">
                {props.actions}
            </div>
        </div>
    </div>;
};

