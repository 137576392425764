import React, { useCallback, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "./NavBar.scss";
import { OverlayPanel } from "primereact/overlaypanel";
import { Breakpoint } from "react-socks";
import { CSSTransition } from "react-transition-group";
import MightyButton from "../mighty-button/MightyButton";
import { BREAKPOINTS } from "../../../index";
import { Menu } from "primereact/menu";
import { useTranslation } from "react-i18next";

export type ViewOption = { label: string, titlePrefix?: string, route: string, disabled?: boolean; active?: boolean, notificationCount?: number };

interface InputProps {
    items: ViewOption[];
}

export const NavBar = (props: InputProps) => {
    const overlayRef = useRef<OverlayPanel>(null);
    const menuRef = useRef<Menu>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const onNavItemClick = useCallback((navItem: ViewOption, e: any) => {
        if (navItem.disabled) {
            overlayRef.current?.toggle(e, undefined);
            e.preventDefault();
        }
        if (navItem.active) {
            e.preventDefault();
        }
    }, []);

    const activeNavItem = props.items.find(item => pathname.startsWith(item.route));

    if (activeNavItem) {
        activeNavItem.active = true;
    }

    const breakpointDown = props.items.length > 13 ? "desktop" : (props.items.length > 8 ? "laptop" : "tablet_landscape");
    const breakpointUp = Object.keys(BREAKPOINTS)[Object.keys(BREAKPOINTS).indexOf(breakpointDown) + 1];
    return (
        <div className="component__navbar">
            <div className="navbar__background"/>
            <OverlayPanel ref={overlayRef} dismissable={true}>
                <span>{t("unaccessible")}</span>
            </OverlayPanel>
            <Breakpoint className="breakpoint" up {...{[breakpointUp]: true}}>
                {props.items.map(it => <NavItem key={it.route} navItem={it} onClick={(e) => onNavItemClick(it, e)}/>)}
            </Breakpoint>
            <Breakpoint className="breakpoint" down {...{[breakpointDown]: true}}>
                <div className="section__active-item" onClick={(e: any) => {
                    e.stopPropagation();
                    menuRef.current?.toggle(e);
                }} ref={divRef}>
                    {activeNavItem && <NavItem navItem={activeNavItem} onClick={(e) => onNavItemClick(activeNavItem, e)}/>}
                    {props.items.length > 1 &&
                        <div className="element__expander"><MightyButton icon="pi-ellipsis-v"/></div>
                    }
                    <Menu
                        appendTo={divRef.current || undefined}
                        popup
                        model={props.items.filter(item => item !== activeNavItem).map(item => ({
                            label: item.label + (item.notificationCount ? ` (${item.notificationCount})` : ""), disabled: item.disabled, command: () => {
                                if (!item.active) {
                                    navigate(item.route);
                                }
                            }
                        }))}
                        ref={menuRef}/>
                </div>
            </Breakpoint>
        </div>);
};

const NavItem = ({navItem, onClick}: { navItem: ViewOption, onClick: (e: any) => void }) => {
    return <CSSTransition timeout={300} classNames="pack" key={navItem.route}>
        <NavLink to={navItem.route} onClick={onClick} className={"element__navitem " + (navItem.disabled ? "disabled" : "")}>
            {navItem.label}
            {!!navItem.notificationCount && <div className="element__notification-indicator">{navItem.notificationCount}</div>}
        </NavLink>
    </CSSTransition>;
}