import React, { ComponentType, ReactNode, useEffect, useMemo } from "react";
import { Filter, getData, LoadableData, PageRequest, PageResponse } from "../../../middleware/model";
import { ActionWithId, useNavigator } from "../../ActionIdNavigator";
import { BasicMasterDetailComponent, WithId } from "./BasicMasterDetailComponent";
import { DetailView, DetailViewInputParams, EditViewInputParams } from "./DetailView";
import { RootState } from "../../../store/root-reducer";
import { useDispatch, useSelector } from "react-redux";
import { FilterComponentProps } from "./filter/FilterBarComponent";
import { BasePagingTableView, SortOption, WithTemplate } from "./BasePagingTableView";
import { AsyncLoadableActionCreator } from "../../../store/store-util";
import { useAsyncWatcher, useTypedAsyncWatcher } from "../async-watcher/UseAsyncWatcher";
import { convertToArray } from "../../../utility-functions";

export interface WatchableTableData<TABLE_TYPE> {
    initialRequest: PageRequest;
    execute: AsyncLoadableActionCreator<PageRequest, PageResponse<TABLE_TYPE>, any>;
    data: LoadableData<PageRequest, PageResponse<TABLE_TYPE>>;
}

export interface WatchableTableDataWithDetail<TABLE_TYPE, DETAIL_TYPE> extends WatchableTableData<TABLE_TYPE> {
    detailData: LoadableData<string, DETAIL_TYPE | undefined>;
}

export interface BasePagingMasterDetailViewProps<TABLE_TYPE extends WithId, SUB_VIEW_ID extends string, DETAIL_TYPE extends WithId = TABLE_TYPE> {
    // table
    tableLoadAction: WatchableTableDataWithDetail<TABLE_TYPE, DETAIL_TYPE>;
    sortOptions?: SortOption[];
    noEntryLabel?: string;
    renderFilterBar?: (filterBarProps: FilterComponentProps) => ReactNode;
    defaultFilters?: Filter<any>[];
    disableTableHistory?: boolean;
    mayAddRow?: boolean;
    addLabel?: string;
    onMultiSelect?: (entries: TABLE_TYPE[]) => void;
    multiSelected?: TABLE_TYPE[];
    multiSelectDisabled?: (item: TABLE_TYPE) => boolean;
    allSelectComponent?: (allSelected: boolean, currentPageIds: TABLE_TYPE[]) => ReactNode;
    reloadTriggeringActions?: AsyncLoadableActionCreator<any, any, any>[];
    headerActions?: ReactNode;
    mainTitle?: ReactNode;

    //detail
    detailActions: DetailActions<DETAIL_TYPE>;
    detailView: ComponentType<DetailViewInputParams<DETAIL_TYPE, SUB_VIEW_ID>>;
    editView?: ComponentType<EditViewInputParams<DETAIL_TYPE>>;
    subEditViews?: { [Property in SUB_VIEW_ID]: ComponentType<EditViewInputParams<DETAIL_TYPE>> };
    initialEntryForAdd?: Partial<DETAIL_TYPE>;
}

export interface DetailActions<DETAIL_TYPE extends WithId> {
    view: AsyncLoadableActionCreator<string, DETAIL_TYPE | undefined, any>;
    create?: AsyncLoadableActionCreator<any, DETAIL_TYPE | undefined, any> | AsyncLoadableActionCreator<any, DETAIL_TYPE | undefined, any>[];
    update?: AsyncLoadableActionCreator<any, DETAIL_TYPE | DETAIL_TYPE[] | undefined | void, any>;
    delete?: AsyncLoadableActionCreator<string, string | undefined, any>;
}

export const useTableLoadAction = <TABLE_TYPE extends any>(
    initialRequest: PageRequest,
    execute: AsyncLoadableActionCreator<PageRequest, PageResponse<TABLE_TYPE>, any>,
    dataSelector: (state: RootState) => LoadableData<PageRequest, PageResponse<TABLE_TYPE>>
): WatchableTableData<TABLE_TYPE> => {
    const data = useSelector(dataSelector);
    return useMemo(() => ({initialRequest, execute, data}), [initialRequest, execute, data]);
};

export const useTableLoadActionWithDetail = <TABLE_TYPE extends any, DETAIL_TYPE extends any>(
    initialRequest: PageRequest,
    execute: AsyncLoadableActionCreator<PageRequest, PageResponse<TABLE_TYPE>, any>,
    dataSelector: (state: RootState) => LoadableData<PageRequest, PageResponse<TABLE_TYPE>>,
    detailDataSelector: (state: RootState) => LoadableData<string, DETAIL_TYPE | undefined>
): WatchableTableDataWithDetail<TABLE_TYPE, DETAIL_TYPE> => {
    const data = useSelector(dataSelector);
    const detailData = useSelector(detailDataSelector);
    return useMemo(() => ({initialRequest, execute, data, detailData}), [initialRequest, execute, data, detailData]);
};

export const BasePagingMasterDetailView = <TYPE extends WithId, SUB_VIEW_ID extends string, DETAIL_TYPE extends WithId = TYPE>(props: BasePagingMasterDetailViewProps<TYPE, SUB_VIEW_ID, DETAIL_TYPE> & WithTemplate<TYPE>) => {
    const {noEntryLabel, detailView, editView, subEditViews, tableLoadAction, sortOptions, mayAddRow, detailActions, disableTableHistory} = props;
    const {item, navigate} = useNavigator();
    const dispatch = useDispatch();
    const detailData = tableLoadAction.detailData;
    const hasLoadedData = detailData && detailData.loaded && !detailData.error;
    const selectedItem = useMemo(() => (hasLoadedData && item.id === detailData.data?.id) ? detailData.data : undefined, [hasLoadedData, detailData, item]);
    const onAdd = useMemo(() => mayAddRow ? () => navigate({action: "add"}) : undefined, [navigate, mayAddRow]);
    const hasDetailData = !!detailData;
    const createActions = useMemo(() => convertToArray(detailActions.create), [detailActions.create]);

    useEffect(() => {
        if (hasDetailData && item.id && (item.action === "view" || item.action === "edit")) {
            dispatch(detailActions.view.request(item.id));
        }
    }, [hasDetailData, dispatch, detailActions.view, item.id, item.action]);

    useTypedAsyncWatcher(detailActions.view, {
        onSuccess: (response) => {
            if (response) {
                navigate({...item, id: response?.id});
            } else {
                // detail service returns undefined -> switch detail type
                navigate({action: ""});
            }
        }
    });
    useTypedAsyncWatcher(detailActions.delete, {
        onSuccess: () => {
            const data = getData(tableLoadAction.data.data);
            if (data.length > 1) {
                const index = data.findIndex(row => row.id === selectedItem?.id);
                navigate({action: "view", id: data[index > 0 ? index - 1 : 1].id});
            } else {
                detailData.data?.id && dispatch(detailActions.view.request(detailData.data.id));
            }
        }
    });
    useTypedAsyncWatcher(createActions, {onSuccess: (response) => navigate({action: "view", id: response?.id})});

    useTypedAsyncWatcher(detailActions.update, {
        onSuccess: (response) => {
            if (!Array.isArray(response)) {
                navigate({action: "view", id: response?.id});
            }
        }
    });
    useAsyncWatcher(props.reloadTriggeringActions, {
        onSuccess: () => {
            if (detailData && item.action === "view") {
                !detailData.loading && detailData.data?.id && dispatch(detailActions.view.request(detailData.data.id));
            }
        }
    });

    const reloadTriggeringActions: AsyncLoadableActionCreator<any, any, any>[] = [
        ...(props.reloadTriggeringActions || []),
        ...(createActions),
        ...(detailActions.delete ? [detailActions.delete] : []),
        ...(detailActions.update ? [detailActions.update] : [])
    ];

    return <BasicMasterDetailComponent
        action={item as ActionWithId}
        onSelectedId={id => navigate({action: id ? "view" : "", id: id})}
        multiSelected={props.multiSelected}
        onMultiSelect={props.onMultiSelect}
        renderTableView={tableProps => <BasePagingTableView
            headerActions={props.headerActions}
            reloadTriggeringActions={reloadTriggeringActions}
            withHistory={!disableTableHistory}
            onMultiSelect={tableProps.onMultiSelect}
            multiSelected={tableProps.multiSelected}
            multiSelectDisabled={props.multiSelectDisabled}
            allSelectComponent={props.allSelectComponent}
            onSelectedId={tableProps.onSelectedId}
            autoSelect={tableProps.autoSelect}
            selectedId={tableProps.selectedId}
            renderFilterBar={props.renderFilterBar}
            defaultFilters={props.defaultFilters}
            tableLoadAction={tableLoadAction}
            itemTemplate={props.itemTemplate}
            sortOptions={sortOptions}
            noEntryLabel={noEntryLabel}
            addLabel={props.addLabel}
            onAdd={onAdd}
            mainTitle={props.mainTitle}
        />}
        renderDetailView={actionWithId => <DetailView
            selectedItem={selectedItem}
            navigatorItem={actionWithId}
            onSuccessfulEdit={() => navigate({action: "view", id: selectedItem?.id})}
            onCancelEdit={() => navigate({action: "view", id: selectedItem?.id})}
            onCancelAdd={() => navigate({action: detailData.data?.id ? "view" : "", id: detailData.data?.id})}
            editView={editView}
            subEditViews={subEditViews}
            detailView={detailView}
            onTriggerEdit={(subView?: string) => navigate({action: "edit", id: selectedItem?.id, subView: (typeof subView === "string" ? subView : "main")})}
            onTriggerAdd={onAdd}
            initialEntryForAdd={props.initialEntryForAdd}
        />}
    />;
};