import React from "react";
import { WithId } from "./BasicMasterDetailComponent";
import { BasePagingMasterDetailView, BasePagingMasterDetailViewProps } from "./BasePagingMasterDetailView";
import { ColumnDef, DataViewItemComponent } from "./DataViewItemComponent";

export interface PagingMasterDetailViewProps<TABLE_TYPE extends WithId, SUB_VIEW_ID extends string, DETAIL_TYPE extends WithId = TABLE_TYPE> extends BasePagingMasterDetailViewProps<TABLE_TYPE, SUB_VIEW_ID, DETAIL_TYPE> {
    title: ColumnDef<TABLE_TYPE>;
    subTitle?: ColumnDef<TABLE_TYPE>;
    columns: ColumnDef<TABLE_TYPE>[];
}

export const PagingMasterDetailView = <TYPE extends WithId, SUB_VIEW_ID extends string, DETAIL_TYPE extends WithId = TYPE>(props: PagingMasterDetailViewProps<TYPE, SUB_VIEW_ID, DETAIL_TYPE>) => {
    const {title, subTitle, columns, ...baseProps} = props;
    return <BasePagingMasterDetailView
        {...baseProps}
        itemTemplate={(entry: TYPE, multiSelection, selected: boolean, multiSelected, onSelect: () => void, onMultiSelect: (seleceted: boolean) => void, itemId: string) =>
            <DataViewItemComponent
                id={itemId}
                multiSelection={multiSelection}
                multiSelected={multiSelected}
                multiSelectDisabled={props.multiSelectDisabled?.(entry)}
                onMultiSelect={onMultiSelect}
                selected={selected}
                entry={entry}
                title={props.title}
                subTitle={props.subTitle}
                columns={props.columns}
                onSelect={onSelect}
            />}
    />;
};
