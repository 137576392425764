import { Dialog } from "primereact/dialog";
import React, { ReactNode, useEffect, useMemo } from "react";
import MightyButton from "../../mighty-button/MightyButton";
import { FilterComponentProps } from "./FilterBarComponent";
import { useTranslation } from "react-i18next";
import "./FilterDialogComponent.scss";
import { Filter } from "../../../../middleware/model";
import equal from "fast-deep-equal/es6";

export interface FilterDialogProps extends FilterComponentProps {
    visible: boolean;
    filterBar: (filterBarProps: FilterComponentProps) => ReactNode;
    hide: () => void;
}

export const areFilterModified = (filters: Filter[], defaultFilters: Filter[]) => !equal(filters, defaultFilters);


export const FilterDialogComponent = (props: FilterDialogProps) => {
    const {t} = useTranslation();
    const hasModifiedFilters = useMemo(() => areFilterModified(props.filters || [], props.defaultFilters || []), [props.filters, props.defaultFilters]);

    useEffect(() => {
        const close = (e: KeyboardEvent) => {
            if (props.visible && e.key === "Escape"){
                props.hide();
            }
        }
        window.addEventListener('keydown', close)
        return () => {
            window.removeEventListener('keydown', close)
        }
    },[props])

    return <Dialog
        header={<div className="section__actions">
            <div>
                <MightyButton
                    class={"icon-text"}
                    disabled={!hasModifiedFilters}
                    icon={"pi-filter-slash"}
                    label={t("remove filters")}
                    onButtonClick={() => props.onFilters(props.defaultFilters || [])}/>
            </div>
            <div>
                <MightyButton
                    label={t("close-dialog")}
                    class={"icon-text"}
                    icon={"pi-times"}
                    onButtonClick={props.hide}/>
            </div>
        </div>
        }
        className={"component__filter-bar-dialog"}
        onHide={() => {
        }}
        modal={false}
        visible={props.visible}
        draggable
        resizable
        closable={false}>
        {props.filterBar ? <div className={"section__table-filter-bar"}>
            {props.filterBar({
                filters: props.filters,
                defaultFilters: props.defaultFilters,
                onFilters: props.onFilters
            })}
        </div> : <div/>}
    </Dialog>;
};

