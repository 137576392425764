import { useTableLoadActionWithDetail } from "../../components/master-detail/BasePagingMasterDetailView";
import { RootState } from "../../../store/root-reducer";
import React from "react";
import { timeTemplate } from "../../components/master-detail/Templates";
import {
    RangeFilterBox,
    DateFilterBox,
    FilterBarComponent,
    FilterComponentProps,
    StringFilterBox,
    AmountFilterBox
} from "../../components/master-detail/filter/FilterBarComponent";
import { PagingMasterDetailView } from "../../components/master-detail/PagingMasterDetailView";
import { RetirementDetailView } from "./RetirementDetailView";
import { RegistryActionCreators } from "../../../store/registry/store";
import { useTranslation } from "react-i18next";
import { Persistence } from "../sink/SinkDetailView";
import { useTransactionId } from "../../../visuals/hooks";
import { MatchMode } from "../../../middleware/model";

const INITIAL_REQUEST = {debounce: 0, size: 10, page: 0, sortField: "timestamp", sortOrder: -1};
const TABLE_DATA_SELECTOR = (state: RootState) => state.registry.findAllRetirements;
const DETAIL_SELECTOR = (state: RootState) => state.registry.findRetirementById;

export const RetirementView = () => {
    const {t} = useTranslation();
    const detailActions = {view: RegistryActionCreators.findRetirementById};
    const tableLoadAction = useTableLoadActionWithDetail(INITIAL_REQUEST, RegistryActionCreators.findAllRetirements, TABLE_DATA_SELECTOR, DETAIL_SELECTOR);
    const transactionId = useTransactionId();

    return <PagingMasterDetailView
        detailActions={detailActions}
        detailView={RetirementDetailView}
        defaultFilters={transactionId ? [{filterField: "transactionId", value: transactionId, matchMode: MatchMode.EQUALS}] : undefined}
        renderFilterBar={FILTER_BAR}
        sortOptions={SORT_OPTIONS}
        tableLoadAction={tableLoadAction}
        title={{render: t => t.externalId}}
        subTitle={{render: r => t("Retirement Timestamp") + ": " + timeTemplate(r.timestamp)}}
        columns={[
            {label: "Amount", render: retirement => retirement.amountInTons},
            {label: "Persistence", render: retirement => <Persistence startYear={retirement.vintage} persistenceInYears={retirement.persistenceInYears}/>},
            {label: "Registrar", render: retirement => retirement.credit.sink.registrar},
        ]}
    />;
};

const SORT_OPTIONS = [
    {label: "Amount", value: "amountInTons"},
    {label: "Retirement Timestamp", value: "timestamp"},
    {label: "Persistence", value: "persistenceInYears"},
];

const FILTER_BAR = (p: FilterComponentProps) =>
    <FilterBarComponent
        filters={p.filters}
        onFilters={p.onFilters}
        filterBoxes={({
            "FULLTEXT": filterProps => <StringFilterBox {...filterProps} label={"Search"}/>,
            "amountInTons": filterProps => <AmountFilterBox {...filterProps} label={"Amount"}/>,
            "timestamp": filterProps => <DateFilterBox {...filterProps} label={"Retirement Timestamp"}/>,
            "persistenceInYears": filterProps => <RangeFilterBox {...filterProps} label={"Persistence"}/>,
        })}
    />;
