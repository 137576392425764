import React from "react";
import "./Footer.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const {t, i18n} = useTranslation();
    const privacyUrl = () => i18n.language === "de" ? "https://www.carbon-standards.com/de/datenschutzerklaerung" : "https://www.carbon-standards.com/en/privacy-statement";
    const legalUrl = () => i18n.language === "de" ? "https://www.carbon-standards.com/de/impressum" : "https://www.carbon-standards.com/en/impressum";

    return <div className="component__footer">
        <div className="section__left">
            <a href={legalUrl()}
               target="_blank"
               rel="noopener noreferrer"
               className="element__navlink">{t("Legal")}
            </a>
            <a href={privacyUrl()}
               target="_blank"
               rel="noopener noreferrer"
               className="element__navlink">{t("Privacy policy")}
            </a>
        </div>
    </div>;
};

export default Footer;
