import { DetailCard } from "../../components/detail-card/DetailCard";
import React from "react";
import { Summary } from "../../components/Summaries";
import { numberTemplate, timeTemplate } from "../../components/master-detail/Templates";
import { ExternalLink } from "../../components/link/Links";
import { DetailViewInputParams } from "../../components/master-detail/DetailView";
import { BaseRetirement, Credit } from "../../../store/registry/types";
import { TableView } from "../../components/master-detail/TableView";
import { REGISTRY_CREDIT_URL } from "../../Root";
import { NavLink } from "react-router-dom";
import { RetirementLink } from "../retirement/RetirementDetailView";
import { useTranslation } from "react-i18next";
import { RegistrySinkLink } from "../sink/SinkDetailView";

export const CreditDetailView = (props: DetailViewInputParams<Credit>) => {
    const credit = props.data;
    const {t} = useTranslation();
    return <div>
        <DetailCard title={credit.externalId}
                    headerActions={<ExternalLink href={credit.externalUrl}/>}>
            <div>
                <div>
                    <Summary
                        entries={[
                            ["Issue Timestamp", timeTemplate(credit.issueTimestamp)],
                            ["credit-amount", numberTemplate("floor", credit.amountInTons, 1, 4)],
                            ["Retired Amount", numberTemplate("floor", credit.retiredAmountInTons, 1, 4)],
                            ["Sink", <RegistrySinkLink id={credit.sink.id} label={credit.sink.externalId}/>],
                            ["sink-amount", numberTemplate("floor", credit.sink.amountInTons, 1, 4)],
                            ["Persistence", credit.persistenceInYears + " " + t("years")],
                            ["Registrar", credit.sink.registrar],
                            ["Type", credit.sink.sinkType],
                            ["Provider", <span>{credit.provider.companyName}<ExternalLink href={credit.provider.externalLink} size={"SMALL"}/></span>],
                        ]}
                    />
                </div>
            </div>
        </DetailCard>
        <DetailCard title={t("Retirements")}>
            <TableView
                data={credit.retirements}
                title={{render: (retirement: BaseRetirement) => <RetirementLink id={retirement.id} label={retirement.id}/>}}
                subTitle={{render: r => timeTemplate(r.timestamp)}}
                columns={[
                    {label: "Amount", render: retirement => retirement.amountInTons},
                    {label: "Persistence", render: retirement => retirement.credit.persistenceInYears + " " + t("years")},
                ]}
            />
        </DetailCard>
    </div>;
};

export const CreditLink = ({id, label}: { id: string, label: string }) => {
    return <NavLink to={REGISTRY_CREDIT_URL + "/view/" + id}>
        <span className={"element__text-link"}>{label}</span></NavLink>;
};
