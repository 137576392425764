import isEqual from "react-fast-compare";

// Applies the given mapping function to all the entries of the given object, returning the mapped results as an object with the same fields
export const mapObject = <IN extends Object, OUT extends { [key in keyof IN]: any }>(object: IN, mappingFunction: (key: keyof IN, value: any) => any): OUT => {
    return Object.fromEntries(Object.entries(object).map(e => [e[0], mappingFunction(e[0] as keyof IN, e[1])])) as OUT;
};

export const convertToArray = <T extends any>(e?: T | T[]): T[] => e ? (Array.isArray(e) ? e : [e]): [];

export const uniqueElementsByEquality = <T>(elements?: T[]): T[] => (elements ?? []).filter(uniqueByEquality);

const uniqueByEquality = <T>(value: T, index: number, self: T[]) => self.findIndex(it => isEqual(it, value)) === index;
