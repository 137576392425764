const callRestEndpoint = <R>(endpoint: string, method: string, headers: HeadersInit, request?: any): Promise<R> =>
    fetch(endpoint, {
        body: request ? JSON.stringify(request) : undefined,
        method: method,
        headers: headers
    }).then(response => {
        if (!response.ok) {
            throw response;
        } else {
            return response.text()
                .then(body => {
                    if (body) {
                        const parse = JSON.parse(body);
                        if (!Array.isArray(parse) && Object.keys(parse).length === 0) {
                            return undefined;
                        }
                        return parse;
                    }
                });
        }
    });

export const get = <R>(endpoint: string, request?: any): (header?: Headers) => Promise<R> => (headers?: Headers) => callRestEndpoint(endpoint + getParams(request), "GET", combineHeaders(headers));
export const post = <R>(endpoint: string, request?: any): (header?: Headers) => Promise<R> => (headers?: Headers) => callRestEndpoint(endpoint, "POST", combineHeaders(headers), request);
export const put = <R>(endpoint: string, request?: any): (header?: Headers) => Promise<R> => (headers?: Headers) => callRestEndpoint(endpoint, "PUT", combineHeaders(headers), request);
export const remove = <R>(endpoint: string, request?: any): (header?: Headers) => Promise<R> => (headers?: Headers) => callRestEndpoint(endpoint, "DELETE", combineHeaders(headers), request);

const combineHeaders = (headers?: Headers) => {
    const combined = new Headers(headers);
    combined.append("Content-Type", "application/json");
    return combined;
};

const getParams = (request: any) => {
    if (request) {
        const par = {} as any;
        Object.keys(request).forEach(it => {
            const value = request[it];
            if (typeof value === "string") {
                par[it] = value;
            } else {
                par[it] = JSON.stringify(value);
            }
        });
        return "?" + new URLSearchParams(par).toString();
    }
    return "";
};

