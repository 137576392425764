import { useTranslation } from "react-i18next";
import React, { Fragment, ReactNode, RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./TableView.scss";
import { Filter, getData, PageRequest } from "../../../middleware/model";
import { Dropdown } from "primereact/dropdown";
import { scrollIntoView } from "../ScrollUtils";
import { WithId } from "./BasicMasterDetailComponent";
import { MightyButtonV2 } from "../mighty-button/MightyButtonV2";
import { FilterComponentProps } from "./filter/FilterBarComponent";
import SparkMD5 from "spark-md5";
import { areFilterModified, FilterDialogComponent } from "./filter/FilterDialogComponent";
import { useNavigate, useLocation } from "react-router-dom";
import { DataView } from "primereact/dataview";
import { Breakpoint } from "react-socks";
import { KeyCode, useComponentDidMount, useKeyPressHandler } from "../../hooks";
import { WatchableTableData } from "./BasePagingMasterDetailView";
import { AsyncLoadableActionCreator } from "../../../store/store-util";
import { useDispatch } from "react-redux";
import { useAsyncWatcher, useTypedAsyncWatcher } from "../async-watcher/UseAsyncWatcher";
import { Spinner } from "../spinner/Spinner";
import MightyButton from "../mighty-button/MightyButton";
import { uniqueElementsByEquality } from "../../../utility-functions";
import { addOrRemoveEntryFromList, getEntryFromList } from "./BaseTableView";

export interface BasePagingTableViewProps<T extends WithId> {
    tableLoadAction: WatchableTableData<T>;
    onSelectedId?: (id?: string) => void;
    selectedId?: string;
    filterBar?: ReactNode;
    noEntryLabel?: string;
    onAdd?: () => void;
    addLabel?: string;
    sortOptions?: SortOption[];
    autoSelect?: boolean;
    withHistory?: boolean;
    hideRefreshButton?: boolean;
    renderFilterBar?: (filterBarProps: FilterComponentProps) => ReactNode;
    defaultFilters?: Filter<any>[];
    mainTitle?: ReactNode;
    onMultiSelect?: (selected: T[]) => void;
    allSelectComponent?: (allSelected: boolean, currentPageIds: T[]) => ReactNode;
    multiSelected?: T[];
    multiSelectDisabled?: (item: T) => boolean;
    reloadTriggeringActions?: AsyncLoadableActionCreator<any, any, any>[];
    headerActions?: ReactNode;
}

export interface SortOption {
    label: string;
    value: string;
}

export const getId = (id: any) => "entry" + SparkMD5.hash(id.toString());

export type WithTemplate<T extends WithId> = { itemTemplate: (entry: T, multiSelection: boolean, selected: boolean, multiSelected: boolean, onSelect: () => void, onMultiSelect: (selected: boolean) => void, itemId: string) => JSX.Element | undefined };

export const BasePagingTableView = <T extends WithId>(props: BasePagingTableViewProps<T> & WithTemplate<T>) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const tableLoadAction = props.tableLoadAction;
    const loadableData = tableLoadAction.data;
    const data = getData(loadableData.data);
    const page = loadableData.data?.page;
    const pageRequest = tableLoadAction.data.params || tableLoadAction.initialRequest;
    const sortField = pageRequest.sortField;
    const rows = page?.size || pageRequest.size || 0; // if page.size is not available we need to use the size from the request. Otherwise, the rows changes after loading and triggers an unwanted onPage event on the DataView component.
    const first = page ? page.number * page.size : 0;
    const selectedId = props.selectedId;
    const onSelectedId = props.onSelectedId;
    const onPage = useCallback((pageRequest: PageRequest) => dispatch(tableLoadAction.execute.request(pageRequest)), [dispatch, tableLoadAction]);
    const hasModifiedFilters = useMemo(() => areFilterModified(pageRequest.filters || [], props.defaultFilters || []), [pageRequest.filters, props.defaultFilters]);
    const showFilters = !!pageRequest.showFilters;
    const multiSelection = !!props.onMultiSelect;
    const multiSelected = props.multiSelected || [];
    const onFilters = useCallback((filters: Filter[]) => pageRequest ? onPage({...pageRequest, filters, page: 0, debounce: 300}) : undefined, [onPage, pageRequest]);
    const currentPageItems: T[] = data.filter(it => !!it);
    const allFromPageAreSelected = currentPageItems.filter(it => it && multiSelected?.find(s => s.id === it.id)).length === currentPageItems.filter(i => !props.multiSelectDisabled?.(i)).length;

    // If the currently selectedId is not on the current page, findIndex will return -1, which is then normalized for both arrow keys to index 0.
    const [hasScrolled, setHasScrolled] = useState<boolean>(false);
    const viewAndScroll = useCallback((id?: string) => {
        if (id) {
            onSelectedId?.(id);
            setHasScrolled(false);
        }
    }, [onSelectedId]);
    const viewPreviousRow = () => viewAndScroll(data[Math.max(data.findIndex(row => row.id === selectedId) - 1, 0)].id);
    const viewNextRow = () => viewAndScroll(data[Math.min(data.findIndex(row => row.id === selectedId) + 1, data.length - 1)].id);
    const viewPreviousPage = () => onPage({...pageRequest, debounce: 0, size: rows, page: Math.max(page?.number - 1, 0)});
    const viewNextPage = () => onPage({...pageRequest, debounce: 0, size: rows, page: Math.max(0, Math.min(page?.number + 1, page?.totalPages - 1))});
    useKeyPressHandler(viewPreviousRow, KeyCode.ARROW_UP);
    useKeyPressHandler(viewNextRow, KeyCode.ARROW_DOWN);
    useKeyPressHandler(viewPreviousPage, KeyCode.ARROW_LEFT);
    useKeyPressHandler(viewNextPage, KeyCode.ARROW_RIGHT);

    // auto selection. if enabled and nothing is selected, we select the first entry...
    useAsyncWatcher(tableLoadAction.execute, {
        onSuccess: (data) => {
            const firstId = getData<T>(data)[0]?.id;
            if (!!props.autoSelect && !selectedId && firstId && onSelectedId) {
                onSelectedId(firstId);
            }
        }
    });

    // if selected entry is in list, we scroll in into view
    const ref = useRef<DataView | null>(null);
    useEffect(() => {
        const current: DataView | null = ref.current;
        if (!hasScrolled && data.length && current && selectedId) {
            setHasScrolled(true);
            scrollIntoView(current.getElement(), getId(selectedId));
        }
    }, [selectedId, data, hasScrolled]);

    useAsyncWatcher(props.reloadTriggeringActions, {onSuccess: () => onPage(pageRequest)});

    // Workaround to clear detail state after user filters out the item
    const [userHasFiltered, setUserHasFiltered] = useState<boolean>(false);
    useTypedAsyncWatcher(props.tableLoadAction.execute, {
        onSuccess: (result) => {
            if (userHasFiltered && selectedId && !getData(result).find(i => i.id === selectedId)) {
                onSelectedId?.(undefined);
            }
            setUserHasFiltered(false);
        }
    });

    // Workaround to not allow tab selection of scrollable area
    const divRef = useRef() as RefObject<HTMLDivElement>;
    useEffect(() => {
        const content = (divRef.current as HTMLElement).getElementsByClassName("p-dataview-content")?.[0] as HTMLElement;
        if (content) {
            content.tabIndex = -1;
        }
    }, []);

    return <div className={"section__master-content"} ref={divRef}>
        {props.renderFilterBar &&
            <FilterDialogComponent visible={showFilters}
                                   filterBar={props.renderFilterBar}
                                   hide={() => onPage({...pageRequest, showFilters: !showFilters})}
                                   onFilters={(filters) => {
                                       setUserHasFiltered(true);
                                       onFilters(filters);
                                   }}
                                   filters={pageRequest.filters || []}
                                   defaultFilters={props.defaultFilters}
            />}
        <InitialTableLoadHandler action={tableLoadAction} withHistory={!!props.withHistory} defaultFilters={props.defaultFilters}/>
        {loadableData.loading && data.length === 0 && <div className="element__loading-spinner"><Spinner size={60}/></div>}
        <DataView
            emptyMessage={t(props.noEntryLabel || "no-entries")}
            ref={ref}
            value={data}
            paginator
            lazy
            header={
                <div className={"section__table-header"}>
                    <div className={"section__table-header-actions"}>
                        {props.mainTitle && <div className={"element__table-header-action"}>
                            {props.mainTitle}
                        </div>}
                        {props.sortOptions && <div className={"element__table-header-action"} title={t("Sort By")}>
                            <Dropdown
                                options={props.sortOptions.map(option => ({label: t(option.label), value: option.value}))}
                                value={sortField}
                                placeholder={t("Sort By")}
                                onChange={(e) => onPage({...pageRequest, sortField: e.value, page: 0, debounce: 0})}
                            />
                            <SortIcon sortOrder={pageRequest.sortOrder || 0}
                                      onSortOrder={(e) => onPage({...pageRequest, sortOrder: e, page: 0, debounce: 0})}
                            />
                        </div>}
                        <div className={"element__table-header-action spacer"}/>
                        {props.renderFilterBar && <div className={"element__table-header-action"}>
                            <MightyButton
                                class={showFilters ? "primary" : "tertiary"}
                                label={t("show-filter-dialog")}
                                icon={"pi-filter"}
                                onButtonClick={() => onPage({...pageRequest, showFilters: !showFilters})}
                            />
                        </div>}
                        <Breakpoint className="breakpoint" desktop up>
                            {props.renderFilterBar && <div className={"element__table-header-action"}>
                                <MightyButton
                                    disabled={!hasModifiedFilters}
                                    class={"icon-text"}
                                    icon={"pi-filter-slash"}
                                    label={t("remove filters")}
                                    onButtonClick={() => onFilters(props.defaultFilters || [])}
                                />
                            </div>}
                        </Breakpoint>
                        {!props.hideRefreshButton && <div className={"element__table-header-action"}>
                            <MightyButton
                                class={"tertiary"}
                                icon="pi-refresh"
                                label={t("reload-data")}
                                asyncAction={tableLoadAction.execute.request(tableLoadAction.data.params || tableLoadAction.initialRequest)}
                            /></div>}
                        {props.onAdd && <div className={"element__table-header-action"}>
                            <MightyButton
                                class="tertiary" icon="pi-plus" label={props.addLabel || t("Add")} onButtonClick={props.onAdd}/>
                        </div>}
                        {props.headerActions && <div className={"element__table-header-action"}>
                            {props.headerActions}
                        </div>}
                        {props.allSelectComponent &&
                            <div
                                className={"element__table-header-action element__all-select-component"}>{props.allSelectComponent(allFromPageAreSelected, currentPageItems.filter(i => !props.multiSelectDisabled?.(i)))}</div>}
                    </div>
                </div>}
            totalRecords={page?.totalElements}
            rows={rows}
            first={first}
            onPage={e => onPage({
                ...pageRequest,
                debounce: 0,
                size: e.rows,
                page: (e.first - e.first % e.rows) / e.rows,
            })}
            paginatorTemplate={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"}
            alwaysShowPaginator={true}
            itemTemplate={entry => {
                const id = entry?.id ? getId(entry?.id) : "entry";
                const onMultiSelected = (selected: boolean) =>
                    props.onMultiSelect && props.onMultiSelect(addOrRemoveEntryFromList(multiSelected, entry, selected));
                return entry ? props.itemTemplate(entry, multiSelection, entry?.id === selectedId || multiSelected.map(e => e.id).includes(entry?.id), !!getEntryFromList(multiSelected, entry), () => onSelectedId && onSelectedId(entry?.id), onMultiSelected, id) :
                    <Fragment/>;
            }}
        />
    </div>;
};

const SortIcon = (props: { sortOrder: number, onSortOrder: (sortOrder: number) => void }) => {
    const {t} = useTranslation();
    const icon = props.sortOrder === 0 ? "pi-sort-alt" : (props.sortOrder > 0 ? "pi-sort-amount-up" : "pi-sort-amount-down");
    return <MightyButtonV2 onButtonClick={() => props.onSortOrder(props.sortOrder === 0 ? 1 : -props.sortOrder)}
                           title={t(props.sortOrder <= 0 ? "sort-ascending" : "sort-descending")} icon={icon}/>;
};

const InitialTableLoadHandler = (props: { action: WatchableTableData<any>, withHistory: boolean, defaultFilters?: Filter[] }) => {
    const initial = props.action.initialRequest;
    const [request, setRequest] = usePageRequestHistory(initial);
    const dispatch = useDispatch();
    const loadableData = props.action.data;
    const params = loadableData.params;
    useEffect(() => {
        if (props.withHistory) {
            setRequest(params);
        }
    }, [params, setRequest, props.withHistory]);

    useComponentDidMount(() => {
        const newRequest = props.withHistory && request ? {...request, debounce: 0} : initial;
        dispatch(props.action.execute.request({...newRequest, filters: uniqueElementsByEquality([...newRequest.filters ?? [], ...props.defaultFilters ?? []])}));
    });
    return null;
};

const usePageRequestHistory = (initial?: PageRequest) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const pageRequestFromSearch = useMemo(() => params.get("pageRequest"), [params]);
    const request = useMemo(() => ({...(pageRequestFromSearch ? JSON.parse(pageRequestFromSearch) : initial), debounce: 0}), [pageRequestFromSearch, initial]);
    const getPageRequestAsString = (request?: PageRequest) => {
        if (request) {
            // @ts-ignore
            delete request.debounce;
            return JSON.stringify(request);
        }
    };
    const setRequest = useCallback((request?: PageRequest) => {
        const pageRequestAsString = getPageRequestAsString(request);
        if (pageRequestAsString && pageRequestAsString !== pageRequestFromSearch) {
            params.set("pageRequest", pageRequestAsString);
            navigate({
                pathname: location.pathname,
                search: "?" + params.toString()
            }, {replace: true});
        }
    }, [pageRequestFromSearch, navigate, location.pathname, params]);
    return [request, setRequest];
};