import React, { Fragment, ReactNode, useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { NavBar, ViewOption } from "../nav-bar/NavBar";
import { Action } from "typesafe-actions";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import { DataLoaderComponent } from "../DataLoaderComponent";

export interface AppRoute extends ViewOption {
    hideInNavBar?: boolean;
    render: () => ReactNode;
}

interface InputProps {
    className?: string;
    routes: AppRoute[];
    loading?: boolean;
    preloadDataActions?: Action<any>[];
    defaultRoute?: string;
    hideNavBar?: boolean;
}

export const AppRouter = (props: InputProps) => {
    const {t} = useTranslation();
    const location = useLocation();
    const activeRoute: AppRoute | undefined = useMemo(() => location.pathname ? props.routes.find(route => location.pathname.startsWith(route.route)) : undefined, [props.routes, location.pathname]);
    const navBarRoutes = useMemo(() => activeRoute?.hideInNavBar ? [] : props.routes.filter(it => !it.hideInNavBar), [activeRoute, props.routes]);

    return <div className={props.className}>
        <TitleUpdater activeRoute={activeRoute}/>
        <DataLoaderComponent
            loading={props.loading}
            action={props.preloadDataActions}
            renderLoaded={() => <Fragment>
                {!props.hideNavBar && <NavBar items={navBarRoutes.map(item => ({...item, label: t(item.label)}))}/>}
                <SwitchTransition>
                    <CSSTransition timeout={150} classNames="transition__appear" key={activeRoute?.route}>
                        <Routes location={location}>
                            {props.routes.map(r =>
                                <Route key={r.route} path={r.route + "/*"} element={r.render()}/>
                            )}
                            {props.defaultRoute && <Route path="*" element={<Navigate to={props.defaultRoute}/>}/>}
                        </Routes>
                    </CSSTransition>
                </SwitchTransition>
            </Fragment>}
        />
    </div>;
};

const TitleUpdater = (props: { activeRoute?: AppRoute }) => {
    const {t} = useTranslation();
    const label = props.activeRoute?.label;
    const translatedLabel = label ? t(label) : "";
    useEffect(() => {
        document.title = (props.activeRoute?.titlePrefix ? t(props.activeRoute?.titlePrefix) + " - " : "") + translatedLabel;
    }, [translatedLabel, t, props.activeRoute?.titlePrefix]);

    return null;
};

