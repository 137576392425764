import React from "react";
import "./TableView.scss";
import { ColumnDef, DataViewItemComponent } from "./DataViewItemComponent";
import { WithId } from "./BasicMasterDetailComponent";
import { BaseTableView, TableViewProps } from "./BaseTableView";


export const TableView = <T extends WithId>(props: TableViewProps<T> & {
    title: ColumnDef<T>;
    subTitle?: ColumnDef<T>;
    columns: ColumnDef<T>[];
}) => {
    const multiSelectDisabled = props.multiSelectDisabled || [];
    const {title, subTitle, columns, mainTitle, ...baseProps} = props;
    return <BaseTableView
        {...baseProps}
        mainTitle={mainTitle}
        itemTemplate={(entry: T, multiSelection, selected: boolean, multiSelected, onSelect: () => void, onMultiSelect: (selected: boolean) => void, itemId: string) => {
            return <DataViewItemComponent
                id={itemId}
                multiSelection={multiSelection && !multiSelectDisabled.find(it => entry.id === it)}
                selected={selected}
                multiSelected={multiSelected}
                onMultiSelect={onMultiSelect}
                entry={entry}
                title={props.title}
                subTitle={props.subTitle}
                columns={props.columns}
                onSelect={onSelect}
            />;
        }}
    />;
};

