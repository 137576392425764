import { isFailure, isSuccessfullyLoaded, LoadableData } from "../../../middleware/model";
import { useCallback, useEffect } from "react";

// TODO think about moving initialRequest to LoadableData
export interface WatchableAction<REQ, RESP> {
    initialRequest?: REQ;
    execute: (request: REQ) => void;
    data: LoadableData<REQ, RESP>;
}

export interface Watchable<RESP, ERR> {
    onSuccess?: (response: RESP) => void;
    onFailure?: (e: ERR) => void;
}

export const getExecute = <REQ, RESP>(action: WatchableAction<REQ, RESP>): () => void => {
    return () => action.data.params ? action.execute(action.data.params) : action.initialRequest ? action.execute(action.initialRequest) : undefined;
};

// TODO hook instead of component
export const LoadableDataWatcher = <REQ, RESP, ERR>(props: { data?: LoadableData<REQ, RESP, ERR>, postAction?: WatchableAction<any, any> } & Watchable<RESP, ERR>) => {
    const {postAction, data, onFailure, onSuccess} = props;
    const success = data && isSuccessfullyLoaded(data);
    const failure = data && isFailure(data);
    const onSuccess1 = useCallback((response?: RESP) => onSuccess && response ? onSuccess?.(response) : undefined, [onSuccess]);
    const onFailure1 = useCallback((e?: ERR) => onFailure && e ? onFailure(e) : undefined, [onFailure]);
    useEffect(() => {
        if (success && data?.data) {
            if (postAction) {
                getExecute(postAction)();
            } else {
                onSuccess1(data.data);
            }
        }
        // eslint-disable-next-line
    }, [success, data?.data]);

    useEffect(() => {
        if (failure && data?.e) {
            onFailure1(data.e);
        }
        // eslint-disable-next-line
    }, [failure, data?.e]);

    if (postAction && !failure) {
        return <LoadableDataWatcher data={postAction.data} onFailure={() => onSuccess1(data?.data)} onSuccess={() => onSuccess1(data?.data)}/>;
    }
    return null;
};
