import { useTranslation } from "react-i18next";
import { Spinner } from "../spinner/Spinner";
import React, { Fragment, ReactNode, RefObject, useEffect, useRef } from "react";
import MightyButton from "../mighty-button/MightyButton";
import { DataView } from "primereact/dataview";
import "./TableView.scss";
import { scrollIntoView } from "../ScrollUtils";
import { useDispatch } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import { WithId } from "./BasicMasterDetailComponent";
import SparkMD5 from "spark-md5";
import { getId, WithTemplate } from "./BasePagingTableView";
import { useAsyncWatcher } from "../async-watcher/UseAsyncWatcher";
import { GenericRequestAction } from "../../../store/store-util";


export interface TableViewProps<T> {
    data: T[],
    onSelectId?: (entryId?: string) => void;
    onMultiSelect?: (entries: T[]) => void;
    multiSelected?: T[];
    multiSelectDisabled?: string[];
    selectedId?: string;
    noEntryLabel?: string
    showReloadButton?: boolean
    reloadActions?: GenericRequestAction[]
    onAdd?: () => void
    onAddIcon?: string;
    addLabel?: string;
    autoSelect?: boolean;
    mainTitle?: ReactNode;
    allSelectComponent?: (allSelected: boolean, entries: T[]) => ReactNode;
}

export const BaseTableView = <T extends WithId>(props: TableViewProps<T> & WithTemplate<T>) => {
    const watcher = useAsyncWatcher(props.reloadActions);
    const {t} = useTranslation();
    const firstItem = props.data[0];
    const autoSelectFirstItem = !!props.autoSelect && !props.selectedId && firstItem;
    const itemId = firstItem?.id;
    const onSelectId = props.onSelectId;
    const multiSelected = props.multiSelected || [];
    const allFromPageAreSelected = multiSelected.length + (props.multiSelectDisabled?.length || 0) === props.data.length;

    useEffect(() => {
        if (autoSelectFirstItem && onSelectId) {
            onSelectId(itemId);
        }
    }, [autoSelectFirstItem, itemId, onSelectId]);

    const ref = useRef<DataView | null>(null);
    useEffect(() => {
        const current = ref.current;
        if (current) {
            if (props.selectedId) {
                scrollIntoView(current.getElement(), "entry" + SparkMD5.hash(props.selectedId));
            }
        }
    }, [props.selectedId]);
    const dispatch = useDispatch();
    useDeepCompareEffect(() => {
        props.reloadActions?.forEach(dispatch);
    }, [dispatch, props.reloadActions]);

    // Workaround to not allow tab selection of scrollable area
    const divRef = useRef() as RefObject<HTMLDivElement>;
    useEffect(() => {
        const content = (divRef.current as HTMLElement).getElementsByClassName("p-dataview-content")?.[0] as HTMLElement;
        if (content) {
            content.tabIndex = -1;
        }
    }, []);

    const pageEntriesWithoutMultiSelectedDisabled = props.data.filter(e => !props.multiSelectDisabled?.find(it => it === e.id));
    return <div className={"section__master-content"} ref={divRef}>
        <DataView
            emptyMessage={t(props.noEntryLabel || "no-entries")}
            ref={ref}
            header={
                <div className={"section__table-header"}>
                    {props.mainTitle && <div className={"element__table-title"}>{props.mainTitle}</div>}
                    <div className={"section__table-header-actions"}>
                        <div>
                            {props.showReloadButton && props.reloadActions &&
                                <MightyButton className="element__reload-entries" icon="pi-refresh"
                                              label={t("reload-data")} asyncAction={props.reloadActions}/>}
                            {props.onAdd && <MightyButton
                                class="tertiary"
                                label={props.addLabel || t("Add")}
                                icon={props.onAddIcon || "pi-plus"}
                                onButtonClick={props.onAdd}/>
                            }
                        </div>
                        {props.allSelectComponent && pageEntriesWithoutMultiSelectedDisabled.length > 0 &&
                            <div
                                className={"element__table-header-action element__all-select-component"}>{props.allSelectComponent(allFromPageAreSelected, pageEntriesWithoutMultiSelectedDisabled)}</div>}
                    </div>
                </div>}
            value={props.data}
            layout={"list"}
            itemTemplate={(entry: T) => {
                const id = entry?.id ? getId(entry?.id) : "entry";
                const onMultiSelect = (selected: boolean) => {
                    return props.onMultiSelect && props.onMultiSelect(addOrRemoveEntryFromList(multiSelected, entry, selected));
                };
                return entry ? props.itemTemplate(entry, !!props.onMultiSelect, entry?.id === props.selectedId, !!getEntryFromList(multiSelected, entry), () => props.onSelectId && props.onSelectId(entry?.id), onMultiSelect, id) :
                    <Fragment/>;
            }}
        />
        {!props.data.length && watcher?.isFirstLoad && <Spinner/>}
    </div>;
};

export const getEntryFromList = <T extends WithId>(list: T[], element: T): T | undefined => list.find(it => it.id === element.id);

const addEntryToList = <T extends WithId>(list: T[], element: T) => {
    if (!getEntryFromList(list, element)) {
        list.push(element);
    }
};

const removeEntryFromList = <T extends WithId>(list: T[], element: T) => {
    const current = getEntryFromList(list, element);
    if (current) {
        const index = list.indexOf(current);
        if (index > -1) {
            list.splice(index, 1);
        }
    }
};

// does not manipulate the list. It returns a new array
export const addOrRemoveFromList = <T extends WithId>(list: T[], toRemoveOrAdd: T[], add: boolean): T[] => {
    const copy = [...list];
    if (add) {
        toRemoveOrAdd.forEach(it => addEntryToList(copy, it));
    } else {
        toRemoveOrAdd.forEach(it => removeEntryFromList(copy, it));
    }
    return copy;
};

// does not manipulate the list. It returns a new array
export const addOrRemoveEntryFromList = <T extends WithId>(list: T[], toRemoveOrAdd: T, add: boolean): T[] => {
    const copy = [...list];
    if (add) {
        addEntryToList(copy, toRemoveOrAdd);
    } else {
        removeEntryFromList(copy, toRemoveOrAdd);
    }
    return copy;
};