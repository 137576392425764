import {FailedEntry, RequestEntry, RequestHistory, RequestState, SucceededEntry} from "./types";
import moment from "moment";
import {AsyncAction} from "../../store-util";
import {getType, PayloadAction} from "typesafe-actions";
import {RequestActionCreators} from "./actions";
import {FailurePayload, RequestPayload, SuccessPayload} from "../../../middleware/model";

const initialState: RequestState = {};
export const DEFAULT_DISPATCH_ID = "DEFAULT_DISPATCH_ID";

export const requestReducer = (state: RequestState = initialState, action: AsyncAction<unknown, unknown, unknown> | PayloadAction<any, any>): RequestState => {
    if (action.type === getType(RequestActionCreators.clearHistory)) {
        const dispatchId = (action as PayloadAction<any, string>).payload;
        if (state[dispatchId]) {
            const newState = {...state};
            delete newState[dispatchId];
            return newState;
        }
        return state;
    }
    if (action.type.endsWith("->")) {
        const tAction = action as PayloadAction<string, RequestPayload<unknown>>;
        const id = tAction.payload.requestId;
        const dispatchId = tAction.payload.meta?.dispatchId ?? DEFAULT_DISPATCH_ID;
        const params = tAction.payload.params;
        const dispatchState = state[dispatchId] ?? {history: {}, ongoing: []};
        return {
            ...state,
            [dispatchId]: {
                ongoing: dispatchState.ongoing.concat([id]),
                history: {
                    ...copyHistoryWithHousekeeping(dispatchState.history),
                    [id]: {
                        id: id,
                        payload: params,
                        type: tAction.type,
                        meta: tAction.payload.meta,
                        requestTime: moment(),
                        status: "ONGOING"
                    }
                }
            }
        };
    }
    const isSuccess = action.type.startsWith("<-");
    const isFailure = action.type.startsWith("!");
    const tAction = (isSuccess ? action as PayloadAction<string, SuccessPayload<unknown, unknown>> : action as PayloadAction<string, FailurePayload<unknown, unknown>>);

    if (isSuccess || isFailure) {
        const dispatchId = tAction.payload.meta?.dispatchId ?? DEFAULT_DISPATCH_ID;
        const dispatchState = state[dispatchId];

        // in case there is not history for the given dispatchId, we do not need to store the result of the call, because it is not needed anymore
        if (typeof dispatchState === "undefined") {
            return state;
        }

        const newHistory = copyHistoryWithHousekeeping(dispatchState.history);
        const updateRequestState = (id: string) => {
            if (!newHistory[id]) {
                console.warn("Attempted to update request which was not in state: " + JSON.stringify(tAction));
                return;
            }
            newHistory[id] = isFailure ? {
                ...dispatchState.history[id] as RequestEntry,
                status: "FAILURE",
                responseTime: moment(),
                durationMs: moment().diff(dispatchState.history[id].requestTime, "ms"),
                error: tAction.payload as FailurePayload<unknown, unknown>
            } as FailedEntry : {
                ...dispatchState.history[id] as RequestEntry,
                status: "SUCCESS",
                responseTime: moment(),
                durationMs: moment().diff(dispatchState.history[id].requestTime, "ms"),
                result: tAction.payload as SuccessPayload<unknown, unknown>
            } as SucceededEntry;
        };

        const updatedIds: string[] = tAction.payload.takeLatest ?
            dispatchState.ongoing.filter(i => isRequestFor(dispatchState.history[i], tAction)) : [tAction.payload.requestId];
        updatedIds.forEach(updateRequestState);

        return {
            ...state,
            [dispatchId]: {
                ongoing: dispatchState.ongoing.filter(i => !updatedIds.includes(i)),
                history: newHistory
            }
        };
    }
    return state;
};

const isRequestFor = (entry: RequestEntry, action: any) => {
    const baseRequestType = (action.type.replace(/^(<-|!)/, "") + "->");
    return entry.status === "ONGOING" && entry.type === baseRequestType;
};

const copyHistoryWithHousekeeping = (history: RequestHistory): RequestHistory => {
    const timeThreshold = moment().subtract(2, "seconds");
    return filterObject<RequestHistory, RequestEntry>(history, (key, value) => {
        return value.status === "ONGOING" || !value.responseTime || value.responseTime.isAfter(timeThreshold);
    }) as RequestHistory;
};

export const filterObject = <IN extends Object, VALUE>(object: IN, predicate: (key: keyof IN, value: VALUE) => boolean): Partial<IN> => {
    return Object.fromEntries(Object.entries(object).filter(e => predicate(e[0] as keyof IN, e[1]))) as Partial<IN>;
};
