import moment from "moment";

export type RoundType = "floor" | "ceil" | "round";

export const formatFixedDecimals = (amount: number, decimals = 0, type: RoundType = "round"): string => {
    return new Intl.NumberFormat(moment.locale(), {
        style: "decimal",
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals

    }).format(decimalAdjust(type, amount, -decimals));
};

const decimalAdjust = (type: RoundType, value: number, exp: number) => {
    // If the exp is undefined or zero...
    if (+exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (!(exp % 1 === 0)) {
        return NaN;
    }
    // Shift
    let value2;
    value2 = value.toString().split("e");
    value2 = Math[type](+(value2[0] + "e" + (value2[1] ? (+value2[1] - exp) : -exp)));
    // Shift back
    value2 = value2.toString().split("e");
    return +(value2[0] + "e" + (value2[1] ? (+value2[1] + exp) : exp));
};


