import React from 'react';
import { createRoot } from "react-dom/client";
import reportWebVitals from './reportWebVitals';
import { applyMiddleware, compose, createStore, Store } from "redux";
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from "./store/root-reducer";
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { Provider } from "react-redux";
import Root from "./visuals/Root";
import RootSaga from "./middleware/RootSaga";
import { BrowserRouter } from "react-router-dom";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = process.env.NODE_ENV === "production" ? compose : (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store: Store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);

export const container = document.getElementById("root");
export const root = createRoot(container!);

sagaMiddleware.run(RootSaga);

export const BREAKPOINTS = {
    never_min: 0,
    mobile: 300,
    mobile_landscape: 480,
    tablet: 768,
    tablet_landscape: 992,
    laptop: 1200,
    desktop: 1680,
    never_max: 8000
};

setDefaultBreakpoints(Object.entries(BREAKPOINTS).map((entry) => ({[entry[0]]: entry[1]})));

root.render(
    <Provider store={store}>
        <BreakpointProvider>
            <BrowserRouter>
                <Root/>
            </BrowserRouter>
        </BreakpointProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
