import React, { Fragment, useMemo, useState } from "react";
import "./Header.scss";
import "./Sidebar.scss";
import "./Menu.scss";
import { Breakpoint } from "react-socks";
import { Sidebar } from "primereact/sidebar";
import { SlideMenu } from "primereact/slidemenu";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { useTranslation } from "react-i18next";
import Languages from "../../i18n/Languages.json";

const Header = () => {
    const [showSideBar, setShowSideBar] = useState<boolean>(false);
    const languageRef = useMemo(() => React.createRef<SlideMenu>(), []);
    const { i18n, t } = useTranslation();
    const activeLanguage = i18n.language;

    const languages = useMemo(() => Object.keys(Languages).map(lang => ({
        label: Languages[lang as keyof typeof Languages],
        command: () => {
            void i18n.changeLanguage(lang);
            languageRef.current?.toggle({} as any);
        },
        disabled: lang === activeLanguage
    })), [i18n, languageRef, activeLanguage]);

    const renderLogoSection = () => {
        return <div className="section__logo">
            <a href={getWebsiteURL()}><Logo/></a>
            <i className="element__menu-toggle pi pi-chevron-right" onClick={hideSideBar}/>
        </div>;
    };

    const renderLanguageSection = () =>
        <div className="section__language" onClick={(e: any) => languageRef.current?.toggle(e)}>
            <i className="pi pi-globe"/>
            <span>{ i18n.language }</span>
        </div>;

    const renderTitleSection = () => {
        return <div className="section__title">
            <div>
                { t("Carbon Sink Registry") }
            </div>
        </div>;
    };

    const getWebsiteURL = () => {
        return "https://www.carbon-standards.com/" + activeLanguage + "/home";
    };

    const hideSideBar = () => setShowSideBar(false);

    return <Fragment>
        <Breakpoint className="breakpoint" tablet_landscape down>
            <div className="component__header">
                <div className="section__inner">
                    {renderLogoSection()}
                    {renderTitleSection()}
                    <div className="section__buttons">
                        {renderLanguageSection()}
                        <i className="element__menu-toggle pi pi-bars" onClick={() => setShowSideBar(!showSideBar)}/>
                    </div>
                </div>
            </div>
            <Sidebar visible={showSideBar} modal={false} onHide={hideSideBar} position="right" className="component__sidebar" showCloseIcon={false}>
                {renderLogoSection()}
                {renderTitleSection()}
            </Sidebar>
        </Breakpoint>
        <Breakpoint className="breakpoint" laptop up>
            <div className="component__header">
                <div className="section__inner">
                    {renderLogoSection()}
                    {renderTitleSection()}
                    {renderLanguageSection()}
                </div>
            </div>
        </Breakpoint>
        {<SlideMenu id="menu__language" model={languages} popup={true} ref={languageRef}/>}
    </Fragment>;
}

export default Header;
