import { combineReducers } from "redux";
import { useSelector } from "react-redux";
import { registryReducer } from "./registry/store";
import { requestReducer } from "./shared/request/reducer";

export const rootReducer = combineReducers({
    registry: registryReducer,
    shared_request: requestReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const useRootSelector = <V>(a: (state: RootState) => V, equalityFn?: (left: V, right: V) => boolean) => {
    return useSelector<RootState, V>(a, equalityFn);
};
