import React, {ComponentType, Fragment, useEffect, useState} from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { WithId } from "./BasicMasterDetailComponent";
import { ActionWithId } from "../../ActionIdNavigator";
import { GenericRequestAction } from "../../../store/store-util";
import { useDispatch } from "react-redux";

export interface DetailViewInputParams<T, SUB_VIEW_ID extends string = "main"> {
    data: T;
    reloadDataActions?: GenericRequestAction[];
    onTriggerEdit?: (editView?: SUB_VIEW_ID) => void;
    onTriggerClone?: (cloneEntry: Partial<T>) => void;
}

export interface EditViewInputParams<INPUT> {
    isEdit?: boolean;
    initialValues?: Partial<INPUT>;
    onSuccessfulSubmit?: () => void,
    onCancel?: () => void;
    reloadDataActions?: GenericRequestAction[];
}

interface DetailViewProps<T extends WithId, SUB_VIEW_ID extends string> {
    selectedItem?: T;
    reloadActions?: GenericRequestAction[];
    navigatorItem: ActionWithId;
    initialEntryForAdd?: Partial<T>;
    detailView: ComponentType<DetailViewInputParams<T, SUB_VIEW_ID>>;
    editView?: ComponentType<EditViewInputParams<T>>;
    subEditViews?: { [Property in SUB_VIEW_ID]: ComponentType<EditViewInputParams<T>> };
    onTriggerEdit?: () => void;
    onTriggerAdd?: () => void;
    onSuccessfulEdit: () => void;
    onSuccessfulAdd?: () => void;
    onCancelEdit: () => void;
    onCancelAdd: () => void;
}

export const DetailView = <T extends WithId, SUB_VIEW_ID extends string>(props: DetailViewProps<T, SUB_VIEW_ID>) => {
    const dispatch = useDispatch();
    const [cloneEntry, setCloneEntry] = useState<Partial<T>>();
    const DETAIL = props.detailView;
    const EDIT = props.subEditViews && props.navigatorItem.subView ? props.subEditViews[props.navigatorItem.subView as SUB_VIEW_ID] as ComponentType<EditViewInputParams<T>> : props.editView;
    const detailType = props.navigatorItem.action;

    useEffect(() => {
        if (detailType !== "add") {
            setCloneEntry(undefined);
        }
    }, [detailType, dispatch]);

    return <SwitchTransition mode="out-in">
        <CSSTransition timeout={150} classNames="transition__appear" key={detailType + props.selectedItem?.id}>
            <Fragment>
                {detailType === "view" && props.selectedItem &&
                    <DETAIL
                        data={props.selectedItem}
                        onTriggerClone={(cloneEntry: Partial<T>) => {
                            setCloneEntry(cloneEntry);
                            props.onTriggerAdd && props.onTriggerAdd();
                        }}
                        onTriggerEdit={props.onTriggerEdit}
                        reloadDataActions={props.reloadActions}
                    />}
                {EDIT && detailType === "edit" && props.selectedItem &&
                    <EDIT
                        isEdit
                        initialValues={props.selectedItem}
                        onSuccessfulSubmit={props.onSuccessfulEdit}
                        onCancel={props.onCancelEdit}
                        reloadDataActions={props.reloadActions}
                    />}
                {EDIT && detailType === "add" &&
                    <EDIT
                        initialValues={cloneEntry || props.initialEntryForAdd}
                        onSuccessfulSubmit={props.onSuccessfulAdd}
                        onCancel={props.onCancelAdd}
                        reloadDataActions={props.reloadActions}
                    />}
            </Fragment>
        </CSSTransition>
    </SwitchTransition>;
};


