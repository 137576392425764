import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const enum KeyCode {
    ESCAPE = 27,
    ARROW_DOWN = 40,
    ARROW_UP = 38,
    ARROW_LEFT = 37,
    ARROW_RIGHT = 39
}

export const useKeyPressHandler = (onKeyDown: () => void, keyCode: number) => {
    const escFunction = useCallback((event: any) => {
        if (event.keyCode === keyCode) {
            onKeyDown();
        }
    }, [onKeyDown, keyCode]);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);
};

// use, if you want to execute something exactly once on mounting
export const useComponentDidMount = (fn: () => void) => {
    useEffect(() => {
        fn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};


const TRANSACTION_ID = "transactionId";

// to support old deep links, we extract the old id and add it as transactionId param
export const useTransactionIdHandler = (original: string, replace: string) => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname.startsWith(original)) {
            navigate({pathname: replace, search: TRANSACTION_ID + "=" + location.pathname.replace(original, "")});
        }
    }, [original, replace, location.pathname, navigate]);
};


export const useTransactionId = () => new URLSearchParams(useLocation().search).get(TRANSACTION_ID);
