import i18next from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en.json";
import de from "./de.json";
import "moment/locale/de";

export const languageBundles = {"en": en, "de": de};

export const useActiveLanguage = () => useTranslation().i18n.language;

export const isLanguageSupported = (lang: string) => Object.keys(languageBundles).indexOf(lang) > -1;

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: languageBundles,
        fallbackLng: "en",
        keySeparator: false,
        nsSeparator: "::",
        debug: false,
        defaultNS: "common",
        fallbackNS: "common",
        returnNull: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18next;
