import React from "react";
import MightyButton from "../../components/mighty-button/MightyButton";
import "./Link.scss";
import { useTranslation } from "react-i18next";

export const ExternalLink = (props: { href?: string, size?: "SMALL" | "LARGE" }) => {
    const {t} = useTranslation();
    return props.href ? <a className={"element__external-link" + (props.size === "SMALL" ? " small" : " large")} href={props.href}
                           rel="noopener noreferrer"
                           target={"_blank"}>
        <MightyButton icon={"pi-external-link"} title={t("title-external-link")}/>
    </a> : null;
};
