import { createContext, PropsWithChildren, useCallback, useMemo, useReducer } from "react";

export interface ContextValue {
    state: {[key: string]: any},
    update: (key: string, value: any) => void,
    reset: () => void
}

export const CONTEXT_STATE = createContext<ContextValue | undefined>(undefined);

type ContextStateAction = {
    type: "SET",
    key: string,
    value: any
} | {
    type: "RESET"
};

const contextStateReducer = (state: {[key: string]: any}, action: ContextStateAction) => {
    switch (action.type) {
        case "SET": return ({...state, [action.key]: action.value});
        case "RESET": return {};
    }
};

export const ContextStateProvider = ({children}: PropsWithChildren<{}>) => {
    const [state, dispatch] = useReducer(contextStateReducer, {});

    // For updating the context state, an asynchronous dispatch is necessary as otherwise child components would update the parent component's local state - which is not permitted.
    // See: https://reactjs.org/link/setstate-in-render
    const delayedDispatch = useCallback((action: ContextStateAction) => setTimeout(() => dispatch(action), 0), []);
    const value = useMemo(() => ({
        state,
        update: (key: string, value: any) => delayedDispatch({type: "SET", key, value}),
        reset: () => delayedDispatch({type: "RESET"})
    }), [state, delayedDispatch]);
    return <CONTEXT_STATE.Provider value={value}>{children}</CONTEXT_STATE.Provider>;
};
