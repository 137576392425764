import { Filter, MatchMode } from "../../../../middleware/model";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import "./FilterBarComponent.scss";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import MightyButton from "../../mighty-button/MightyButton";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";

export interface FilterComponentProps {
    onFilters: (filters: Filter[]) => void;
    defaultFilters?: Filter<any>[];
    filters: Filter[];
}

type FilterBoxes = { [key: string]: (filterProps: FilterProps) => ReactNode };

export const FilterBarComponent = (props: FilterComponentProps & { filterBoxes: FilterBoxes }) => {
    const {t} = useTranslation();
    return <div className={"component__filter-bar"}>
        {Object.keys(props.filterBoxes).map(key => {
            const filters = props.filters.filter(it => it.filterField === key);
            const filterBox = props.filterBoxes[key];
            return <div className={"element__filter-box"} key={key}>
                {filterBox({
                    filterField: key,
                    filters: filters,
                    onFilter: ((filter: Filter) => {
                        props.onFilters(combineFilter(props.filters, filter));
                    })
                })}
                <div className={"element__filter-box-remover"}>
                    <MightyButton icon={"pi-trash"}
                                  title={t("remove filter")}
                                  disabled={filters.length === 0}
                                  onButtonClick={() => props.onFilters(props.filters.filter(it => it.filterField !== key))}/>
                </div>
            </div>;
        })}
    </div>;
}

const combineFilter = (filters: Filter[], filter: Filter) => {
    return filter.value !== undefined ? replaceOrAddFilter(filters, filter) : removeFilter(filters, filter.filterField, filter.matchMode);
};

const replaceOrAddFilter = (filters: Filter[], filter: Filter) => {
    const newFilters = [...filters];
    const indexOf = filters.findIndex(it => it.filterField === filter.filterField && it.matchMode === filter.matchMode);
    if (indexOf > -1) {
        newFilters[indexOf] = filter;
    } else {
        newFilters.push(filter);
    }
    return newFilters;
};

const removeFilter = (filters: Filter[], filterField: string, matchMode: MatchMode) => {
    const indexOf = filters.findIndex(it => it.filterField === filterField && it.matchMode === matchMode);
    if (indexOf > -1) {
        const newFilters = [...filters];
        newFilters.splice(indexOf, 1);
        return newFilters;
    } else {
        return filters;
    }
};

export interface FilterProps {
    filterField: string;
    onFilter: (filter: Filter<any>) => void;
    filters?: Filter<any>[];
}

export const StringFilterBox = (props: FilterProps & { matchMode?: MatchMode, placeholder?: string, label?: string }) => {
    const {t} = useTranslation();
    const filterValue = useMemo(() => (props.filters?.find(() => true)?.value || "") as string, [props]);
    const [input, setInput] = useState<string>(filterValue);
    useEffect(() => {
        if (!filterValue) {
            setInput(filterValue);
        }
    }, [filterValue]);
    const onFilter = useCallback((value: string | undefined) =>
        props.onFilter({
            value: value,
            filterField: props.filterField,
            matchMode: props.matchMode ? props.matchMode : MatchMode.EQUALS
        }), [props]);

    return <div className={"section__filter-box section__string-filter"}>
        <div>
            {props.label && <label className="element__label">{t(props.label)}</label>}
        </div>
        <div className={"section__filter-row"}>
            <InputText value={input}
                       onChange={(e: any) => {
                           setInput(e.target.value);
                           const newFilterValue = e.target.value || undefined;
                           debounce(onFilter, 300)(newFilterValue);
                       }}/>
        </div>
    </div>;
};

const debounce = <T extends any[]>(fn: (...args: T) => void, debounceInterval: number): (...args: T) => void => {
    let timer: NodeJS.Timeout | undefined;
    return (...args: T) => {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = undefined;
            fn.apply(context, args);
        }, debounceInterval);
    };
};

// Decimals: Leave undefined if only whole numbers should be entered.
export const RangeFilterBox = (props: FilterProps & { label: string, decimals?: number }) => {
    const {t, i18n} = useTranslation();
    const to = useMemo(() => props.filters?.find(it => it.matchMode === MatchMode.LTE)?.value as number | undefined, [props]);
    const from = useMemo(() => props.filters?.find(it => it.matchMode === MatchMode.GTE)?.value as number | undefined, [props]);

    const [fromInput, setFrom] = useState<number | undefined>(from);
    const [toInput, setTo] = useState<number | undefined>(to);

    // If props filter value is set to undefined, the filter was cleared, and so should the input fields.
    useEffect(() => {
        if (!to) setTo(to);
    }, [to]);
    useEffect(() => {
        if (!from) setFrom(from);
    }, [from]);

    return <div className={"section__filter-box section__range-filter"}>
        <div>
            <label className="element__label">{t(props.label)}</label>
        </div>
        <div className={"section__filter-row"}>
            <InputNumber value={fromInput}
                         placeholder={t("from")}
                         onValueChange={(e) => {
                             const value = e.value ?? undefined;
                             setFrom(value);
                             props.onFilter({value: value, filterField: props.filterField, matchMode: MatchMode.GTE});
                         }}
                         mode="decimal"
                         locale={i18n.language}
                         maxFractionDigits={props.decimals} />
            <span className="element__label"> — </span>
            <InputNumber value={toInput}
                         placeholder={t("to")}
                         onValueChange={(e) => {
                             const value = e.value ?? undefined;
                             setTo(value);
                             props.onFilter({value: value, filterField: props.filterField, matchMode: MatchMode.LTE});
                         }}
                         mode="decimal"
                         locale={i18n.language}
                         maxFractionDigits={props.decimals} />
        </div>
    </div>;
};

// Same as a Range Filter, but allows 2 decimals.
export const AmountFilterBox = (props: FilterProps & { label: string }) =>
    <RangeFilterBox {...props} decimals={2} />

export const DateFilterBox = (props: FilterProps & { label: string }) => {
    const {t} = useTranslation();
    const toDateString = props.filters?.find(it => it.matchMode === MatchMode.LT)?.value;
    const fromDateString = props.filters?.find(it => it.matchMode === MatchMode.GTE)?.value;
    const from = fromDateString ? moment(fromDateString).toDate() : undefined;
    const to = toDateString ? moment(toDateString).subtract(1, "day").toDate() : undefined;
    return <div className={"section__filter-box section__date-filter"}>
        <label className="element__label">{t(props.label)}</label>
        <div className={"section__filter-row"}>
            <Calendar placeholder={t("from")} showIcon value={from} onChange={f => {
                const newValue = adjustFromDate(f.value as Date | undefined);
                props.onFilter({value: newValue, filterField: props.filterField, matchMode: MatchMode.GTE});
            }}/>
            <span className="element__label"> — </span>
            <Calendar placeholder={t("to")} showIcon value={to} onChange={t => {
                const newValue = adjustToDate(t.value as Date | undefined);
                props.onFilter({value: newValue, filterField: props.filterField, matchMode: MatchMode.LT});
            }}/>
        </div>
    </div>;
};

export type Option<T = string> = { label: string, value: T };

export const SelectionFilterBox = (props: FilterProps & { placeholder?: string, label?: string, options: Option[] }) => {
    const {t} = useTranslation();
    return <div className={"section__filter-box"}>
        <div>
            {props.label && <label className="element__label">{t(props.label)}</label>}
        </div>
        <div className={"section__filter-row"}>
            <Dropdown options={props.options.map(it => ({label: t(it.label), value: it.value}))} value={props.filters?.find(() => true)?.value}
                      onChange={(e) => {
                          props.onFilter({value: e.target.value, filterField: props.filterField, matchMode: MatchMode.EQUALS});
                      }}
            />
        </div>
    </div>;
};

const adjustFromDate = (fromDate: Date | undefined) => fromDate ? moment(fromDate).toISOString(true) : undefined;

const adjustToDate = (toDate: Date | undefined) => toDate ? moment(toDate).add(1, "day").toISOString(true) : undefined;
