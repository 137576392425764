import React, { createContext, Fragment } from "react";
import { SinkView } from "./views/sink/SinkView";
import { CreditView } from "./views/credit/CreditView";
import { useLanguageHandler } from "./LanguageHandler";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import "./styles/root-styles.scss";
import { AppRouter } from "./components/app-router/AppRouter";
import "./i18n/i18n";
import { RetirementView } from "./views/retirement/RetirementView";
import { useTransactionIdHandler } from "../visuals/hooks";

export const REGISTRY_SINK_URL = "/sink";
export const REGISTRY_CREDIT_URL = "/credit";
export const REGISTRY_RETIREMENT_URL = "/retirement";

// these deep links are already published and must not be changed
export const REGISTRY_CREDIT_TRANSACTIONS_URL = "/credits";
export const REGISTRY_RETIREMENT_TRANSACTIONS_URL = "/retirements";

/* Custom Contexts */
export const CollapseButtonLabelContext = createContext<boolean>(false);

export const Root = () => {
    useLanguageHandler();
    useTransactionIdHandler(REGISTRY_CREDIT_TRANSACTIONS_URL + "/view/", REGISTRY_CREDIT_URL);
    useTransactionIdHandler(REGISTRY_RETIREMENT_TRANSACTIONS_URL + "/view/", REGISTRY_RETIREMENT_URL);
    return <Fragment>
        <Header/>
        <div className="section__middle-section">
            <div className="section__page-content">
                <AppRouter
                    className="app app__registry"
                    routes={[
                        {
                            label: "Carbon Sinks",
                            route: REGISTRY_SINK_URL,
                            titlePrefix: "Registry",
                            render: () => <SinkView/>
                        },
                        {
                            label: "Carbon Sink Credits",
                            route: REGISTRY_CREDIT_URL,
                            titlePrefix: "Registry",
                            render: () => <CreditView/>
                        },
                        {
                            label: "Carbon Sink Credit Retirements",
                            route: REGISTRY_RETIREMENT_URL,
                            titlePrefix: "Registry",
                            render: () => <RetirementView/>
                        }
                    ]}
                    defaultRoute={REGISTRY_SINK_URL}
                />
            </div>
        </div>
        <Footer/>
    </Fragment>;
};

export default Root;
