import { getType, PayloadAction } from "typesafe-actions";
import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AsyncLoadableActionCreator } from "../../store/store-util";
import { PageRequest, RequestPayload } from "../model";

export const wrapTakeLatest = <Q, S_EXPECTED, S_RECEIVED extends S_EXPECTED = S_EXPECTED>(actionCreator: AsyncLoadableActionCreator<Q, S_EXPECTED, any>, handler: ((data: Q) => (headers?: Headers) => Promise<S_RECEIVED>)) => {
    const fn = wrapLoadableHandler(actionCreator, handler, true);
    return takeLatest(fn.action, fn.handler);
};

export const wrapTakeEvery = <Q, S_EXPECTED, S_RECEIVED extends S_EXPECTED = S_EXPECTED>(actionCreator: AsyncLoadableActionCreator<Q, S_EXPECTED, any>, handler: ((data: Q) => (headers?: Headers) => Promise<S_RECEIVED>)) => {
    const fn = wrapLoadableHandler(actionCreator, handler, false);
    return takeEvery(fn.action, fn.handler);
};

// The rest interceptor service calls
// -> handles error codes and prepares error messages, collecting as much info as possible
export function wrapLoadableHandler<Q, S>(actionCreator: AsyncLoadableActionCreator<Q, S, any>, handler: ((data: Q) => (headers?: Headers) => Promise<S>), takeLatest: boolean = false) {
    const type = getType(actionCreator.request);
    const wrappedHandler = function* (data: PayloadAction<string, RequestPayload<Q>>) {
        const params = data.payload.params;
        const requestId = data.payload.requestId;
        const meta = data.payload.meta ?? {};

        try {
            if ((params as PageRequest)?.debounce && (params as PageRequest).debounce > 0) {
                yield delay((params as PageRequest).debounce);
            }
            const returnVal: S = yield call(handler(params), new Headers());
            yield put(actionCreator.success({requestId, params, data: returnVal, meta, takeLatest}));
            return {params: params, data: returnVal};
        } catch (error) {
            const failureInfo: FailureInfo = yield call(gatherErrorInfo, error);
            failureInfo["request"] = {
                type, payload: params
            };

            // Handle common error cases which needs to be handled specifically
            if (failureInfo.status === 401) {

            } else if (failureInfo.status === 403) {
                failureInfo.message = "operation-forbidden";
            } else if (failureInfo.status === 504) {
                failureInfo.message = "connection-failed";
            }
            yield put(actionCreator.failure({requestId, e: failureInfo, params, meta, takeLatest}));
            return false;
        }
    };

    return {action: type, handler: wrappedHandler};
}

// In the case of an error, we attempt to collect as much info as possible from the body of the response.
const gatherErrorInfo = function* (error: any) {
    const failureInfo: FailureInfo = {
        response: error,
        message: error.message || error.statusText,
        status: error.status,
        params: error.params || []
    };

    try {
        const detail: { error?: string, message?: string } = yield error.json();
        if (typeof detail.error === "string") {
            failureInfo.message = detail.message || detail.error;
        } else if (detail.message) {
            Object.assign(failureInfo, detail);
            failureInfo.status = failureInfo.status || failureInfo.statusCode;
        }
    } catch (e) {
    }

    if (failureInfo.status === 404) {
        failureInfo.message = "not-found";
    }
    if (!failureInfo.status) {
        failureInfo.message = "connection-failed";
    }
    return failureInfo;
};

interface FailureInfo {
    request?: any;
    response: any;
    message?: string;
    status?: number;
    params: string[];
    statusCode?: number;
}
