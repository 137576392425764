import { ActionType, createAsyncAction, getType, PayloadActionCreator } from "typesafe-actions";
import { FailurePayload, LoadableData, PageRequest, PageResponse, RequestPayload, SuccessPayload } from "../middleware/model";
import { PayloadAction } from "typesafe-actions/dist/type-helpers";

export type GenericRequestAction = PayloadAction<string, RequestPayload<any>>;

export interface AsyncActionMetaInfo {
    suppressErrorNotificationToast?: boolean;
    dispatchId?: string;
}

export interface AsyncLoadableActionCreator<REQ, RESP, ERR> {
    request: (payload: REQ, meta?: AsyncActionMetaInfo) => PayloadAction<string, RequestPayload<REQ>>,
    success: PayloadActionCreator<string, SuccessPayload<REQ, RESP>>,
    failure: PayloadActionCreator<string, FailurePayload<REQ, ERR>>,
}

export type AsyncAction<REQ, RESP, ERR> = PayloadAction<string, RequestPayload<REQ> | SuccessPayload<REQ, RESP> | FailurePayload<REQ, ERR>>;

export function generateUID() {
    return ("000" + (((Math.random() * 46656) | 0).toString(10))).slice(-3)
        + ("000" + (((Math.random() * 46656) | 0).toString(10))).slice(-3);
}

export const createLoadableAsyncAction = (namespace: string, action: string) => <REQ, RES, ERR = any>(): AsyncLoadableActionCreator<REQ, RES, ERR> => {
    const actionName = namespace + "/" + action;
    // @ts-ignore
    return createAsyncAction([actionName + "->", (params: REQ, meta: AsyncActionMetaInfo) => ({params, requestId: generateUID(), meta})], "<-" + actionName, "!" + actionName)<REQ, RES>();
};

export const createReducerForLoadableData = <REQ, RESP, ERR = any>(load: AsyncLoadableActionCreator<REQ, RESP, ERR>, init: LoadableData<REQ, RESP, ERR>, removeDataDuringLoad?: boolean) =>
    (state: LoadableData<REQ, RESP, ERR> = init, action: ActionType<{ [key: string]: typeof load }>): LoadableData<REQ, RESP, ERR> => {
        switch (action.type) {
            case getType(load.request):
                return ({params: (action.payload as RequestPayload<REQ>).params, data: removeDataDuringLoad ? init.data : state.data, error: false, loading: true, loaded: state.loaded});
            case getType(load.success):
                return {...action.payload as SuccessPayload<REQ, RESP>, error: false, loading: false, loaded: true};
            case getType(load.failure):
                return {...(action.payload as FailurePayload<REQ, ERR>), data: state.data, error: true, loading: false, loaded: true};
            default:
                return state;
        }
    };

// Creates a reducer for PageResponse data, that will always append newly loaded data to the existing data in the state.
// The underlying assumption is that data is loaded in order, meaning not sparsely (not jumping pages).
// If the state contained more entries than is to be expected by the page request (e.g. contains 7 elements, and we load page #2 with page size 3)
// the overlap is removed from the state and replaced with the newly loaded elements.
export const createReducerForPagedLoadableData = <RESP, ERR = any>(load: AsyncLoadableActionCreator<PageRequest, PageResponse<RESP>, ERR>, init: LoadableData<PageRequest, PageResponse<RESP>, ERR>) =>
    (state: LoadableData<PageRequest, PageResponse<RESP>, ERR> = init, action: ActionType<{ [key: string]: typeof load }>): LoadableData<PageRequest, PageResponse<RESP>, ERR> => {
        switch (action.type) {
            case getType(load.request):
                return ({params: (action.payload as RequestPayload<PageRequest>).params, data: state.data, error: false, loading: true, loaded: state.loaded});
            case getType(load.success):
                const p = (action.payload as SuccessPayload<PageRequest, PageResponse<RESP>>);
                if (!p.data._embedded) {
                    return {...p, error: false, loading: false, loaded: true};
                }
                const key =  Object.keys(p.data._embedded)[0];
                const data = state.data._embedded[key] || [];
                if (data.length > (p.data.page.number * p.data.page.size)) {
                    data.splice(p.data.page.number * p.data.page.size);
                } else if (data.length < (p.data.page.number * p.data.page.size)) {
                    throw new Error('PagedLoadableData Reducer noticed missing data: Loaded ' + data.length + ' but expected ' + (p.data.page.number * p.data.page.size));
                }
                data.push(...p.data._embedded[key]);
                return {...p, data: {...p.data, _embedded: {[key]: data}}, error: false, loading: false, loaded: true};
            case getType(load.failure):
                return {...(action.payload as FailurePayload<PageRequest, ERR>), data: state.data, error: true, loading: false, loaded: true};
            default:
                return state;
        }
    };
