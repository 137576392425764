import { Action } from "typesafe-actions";
import React, { ReactElement, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAsyncWatcher } from "./async-watcher/UseAsyncWatcher";

interface DataLoaderProps {
    action?: Action[]
    loading?: boolean;
    loaderText?: string;
    renderLoaded: () => ReactElement;
}

export const DataLoaderComponent = (props: DataLoaderProps) => {
    const watcher = useAsyncWatcher(props.action);
    const dataHasLoaded = useMemo(() => watcher && !watcher?.ongoing, [watcher]);
    const loaded = useMemo(() => (!props.action || !!dataHasLoaded) && !props.loading, [props.action, dataHasLoaded, props.loading]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (props.action?.length) {
            props.action.forEach(dispatch);
        }
    }, [props.action, dispatch]);

    return loaded ? props.renderLoaded() : <Loader loaderText={props.loaderText}/>;
};

const Loader = ({loaderText}: { loaderText?: string }) => {
    const {t} = useTranslation();
    return <div className="element__placeholder-loading"><i className="pi pi-spin pi-spinner"/>{loaderText && <span>{t(loaderText)}...</span>}</div>;
};
