import { useSelector } from "react-redux";
import { RootState } from "../../root-reducer";
import { usePrevious } from "../../../visuals/components/async-watcher/UseAsyncWatcher";
import { useMemo } from "react";
import { DEFAULT_DISPATCH_ID } from "./reducer";

export const useRequestHistoryForDispatchId = (dispatchId?: string) => {
    const requestState = useSelector((root: RootState) => root.shared_request);
    const previousRequestState = usePrevious(requestState);
    const storeSharedRequest = useMemo(() =>
        requestState[dispatchId ?? DEFAULT_DISPATCH_ID] ?? {history: {}, ongoing: []}, [dispatchId, requestState]);
    const prevStoreSharedRequest = useMemo(() =>
        previousRequestState ? previousRequestState[dispatchId ?? DEFAULT_DISPATCH_ID] ?? {history: {}, ongoing: []} : undefined, [dispatchId, previousRequestState]);
    return {storeSharedRequest, prevStoreSharedRequest};
};
