import { DetailCard } from "../../components/detail-card/DetailCard";
import { SequestrationCurvesComponent } from "../../components/sequestration-curve/SequestrationCurvesComponent";
import React, { Fragment } from "react";
import { Summary } from "../../components/Summaries";
import { countryTemplate, timeTemplate, numberTemplate } from "../../components/master-detail/Templates";
import { ExternalLink } from "../../components/link/Links";
import { DetailViewInputParams } from "../../components/master-detail/DetailView";
import { SequestrationCurve, Sink, BaseCredit, BaseRetirement } from "../../../store/registry/types";
import { NavLink } from "react-router-dom";
import { REGISTRY_SINK_URL } from "../../Root";
import { useTranslation } from "react-i18next";
import { CurveColor } from "../../components/sequestration-curve/CurvesComponent";
import { TableView } from "../../components/master-detail/TableView";
import { CreditLink } from "../../views/credit/CreditDetailView";
import { RetirementLink } from "../retirement/RetirementDetailView";

export const CurvePersistence = ({curve}: { curve: SequestrationCurve }) => {
    const {t} = useTranslation();
    const endYear = curve.endYear;
    const startYear = curve.startYear;
    const range = startYear + " - " + endYear;
    const duration = curve.values.length;
    return <Fragment>{range + " (" + duration + " " + t("years") + ")"}</Fragment>;
};

export const Persistence = ({startYear, persistenceInYears}: { startYear: number, persistenceInYears: number }) => {
    const {t} = useTranslation();
    const endYear = startYear + persistenceInYears - 1;
    const range = startYear + " - " + endYear;
    return <Fragment>{range + " (" + persistenceInYears + " " + t("years") + ")"}</Fragment>;
};

export const SinkDetailView = (props: DetailViewInputParams<Sink>) => {
    const sink = props.data;
    const sequestrationCurve = sink.sequestrationCurve;
    const startYear = sequestrationCurve.startYear;
    const endYear = sequestrationCurve.endYear;
    const {t} = useTranslation();
    return <div>
        <DetailCard title={sink.externalId}
                    headerActions={<ExternalLink href={sink.externalUrl}/>}>
            <div>
                <SequestrationCurvesComponent
                    sequestrationCurves={[{...sequestrationCurve, label: "Total", color: CurveColor.PRIMARY, decimals: 4}]}
                />
                <div>
                    <Summary entries={[
                        ["Registrar", sink.registrar],
                        ["Type", t(sink.sinkType)],
                        ["Amount", sink.amountInTons],
                        ["Issued Amount", sink.issuedAmountInTons],
                        ["Retired Amount", sink.retiredAmountInTons],
                        ["Persistence", <Persistence startYear={sink.vintage} persistenceInYears={sink.persistenceInYears}/>],
                        ["Certification Timestamp", timeTemplate(sink.certificationTimestamp)],
                        ["Time Range", startYear + " - " + endYear],
                        ["Country", sink.countryCode && countryTemplate(sink.countryCode)],
                        ["Provider", <span>{sink.provider.companyName}<ExternalLink href={sink.provider.externalLink} size={"SMALL"}/></span>],
                    ]}
                    />
                </div>
            </div>
        </DetailCard>
        <DetailCard title={t("issued-credits")}>
            <TableView
                title={{
                    render: (credit: BaseCredit) => <CreditLink id={credit.id} label={credit.externalId}/>
                }}
                data={sink.credits}
                columns={[
                    {label: "credit-amount", render: (d: BaseCredit) => numberTemplate("floor", d.amountInTons, 1, 4)},
                ]}
            />
        </DetailCard>
        <DetailCard title={t("Retirements")}>
            <TableView
                data={sink.retirements}
                title={{
                    render: (retirement: BaseRetirement) =>
                        <RetirementLink id={retirement.id} label={retirement.id}/>
                }}
                subTitle={{render: r => timeTemplate(r.timestamp)}}

                columns={[
                    {label: "Amount", render: retirement => retirement.amountInTons},
                    {label: "Credit", render: retirement => <CreditLink id={retirement.credit.id} label={retirement.credit.externalId}/>}
                ]}
            />
        </DetailCard>
    </div>;
};

export const RegistrySinkLink = ({id, label}: { id: string, label: string }) => {
    return <NavLink to={REGISTRY_SINK_URL + "/view/" + id}>
        <span className={"element__text-link"}>{label}</span></NavLink>;
};
