import React, { Fragment, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
export type SummaryEntry = [ReactNode | string | undefined, ReactNode];

export const Summary = ({title, titleComponent, entries}: { title?: string, titleComponent?: ReactNode, entries: SummaryEntry[] }) => {
    const {t} = useTranslation();
    return <Fragment>
        {title && <div className="element__subcaption">
            <div>{t(title)}</div>
            <div className={"element__title-component"}>{titleComponent}</div>
        </div>}
        <div className="section__summary">
            {entries.filter(e => !!e[1] || e[1] === 0).map(e => {
                const keyComponent = e[0];
                if (!keyComponent) {
                    return <Fragment key={"-"}>
                        {<span>{""}</span>}
                        {<span>{e[1]}</span>}
                    </Fragment>;
                }
                if (typeof keyComponent === "string") {
                    return <Fragment key={keyComponent}>
                        {<span>{t(keyComponent)}</span>}
                        {<span>{e[1]}</span>}
                    </Fragment>;
                }
                return <Fragment key={v4()}>
                    {keyComponent}
                    {<span>{e[1]}</span>}
                </Fragment>;
            })}
        </div>
    </Fragment>;
};
