import { getName, registerLocale } from "i18n-iso-countries";
import lang_en from "i18n-iso-countries/langs/en.json";
import lang_de from "i18n-iso-countries/langs/de.json";
import moment from "moment";

registerLocale(lang_en);
registerLocale(lang_de);

export const GERMANY = "DE";
export const euCountries = [
    "BE", "GR", "LT", "PT", "BG", "ES", "LU", "RO", "CZ", "FR", "HU", "SI"
    , "DK", "HR", "MT", "SK", GERMANY, "IT", "NL", "FI", "EE", "CY", "AT", "SE"
    , "IE", "LV", "PL"
];
export const nonEuCountries = ["AU", "GB", "IS", "NO", "LI", "CH", "US"];

export const countryList = euCountries.concat(nonEuCountries);

export const countryNames = (languageCode: string) => countryList.map(it => getName(it, languageCode));

export const countryNamesPerCode = (languageCode: string) => countryList.map(it => ({
    label: getName(it, languageCode),
    value: it
})).sort((a, b) => a.label.localeCompare(b.label));

export const getCountryName = (countryName?: string): string | undefined =>
    countryName && getName(countryName, moment.locale());
