import { useCallback, useMemo } from "react";
import { useNavigate, useMatch, useLocation, useParams } from "react-router-dom";
import { BREAKPOINTS } from "../index";

export type ActionType = "add" | "view" | "edit" | "";

export interface ActionNavigator {
    item: ActionWithId;
    navigate: (item: ActionWithId) => void;
    view: (id?: string) => void;
    edit: () => void;
    add: () => void;
    cancel: () => void;
}

export interface ActionWithId {
    action: ActionType;
    subView?: string;
    id?: string;
}

const getPath = (item: ActionWithId) => {
    const idPart = (item.id ? "/" + item.id : "");
    const actionPart = (item.action ? "/" + item.action : "");
    const subViewPart = (item.subView ? "/" + item.subView : "");
    return actionPart + subViewPart + idPart;
};

const useBaseUrl = () => {
    const pathname = useLocation()["pathname"];
    const params = useParams()["*"] ?? "";
    return params ? pathname.replace("/" + params, "") : pathname;
};

export const useNavigator = (): ActionNavigator => {
    const item = useActionWithId();
    const navigate = useActionWithIdNavigate();
    const view = useCallback((id?: string) => navigate({id, action: "view"}), [navigate]);
    const edit = useCallback(() => navigate({id: item.id, action: "edit"}), [navigate, item]);
    const add = useCallback(() => navigate({action: "add"}), [navigate]);
    const cancel = useCallback(() => navigate({action: ""}), [navigate]);
    return useMemo(() => ({item, view, edit, cancel, add, navigate}), [item, view, edit, cancel, add, navigate]);
};

// custom logic to match the path to our action/subView/id system
const useActionWithId = (): ActionWithId => {
    const param = useMatch(useBaseUrl() + "/*")?.params["*"];
    return useMemo(() => {
        const split = (param || "").split("/");
        if (!split) {
            return {action: ""};
        }
        const action = split[0] as ActionType;
        switch (split.length) {
            case 1 :
                return {action, subView: "main"};
            case 2 :
                return {action, subView: action === "add" ? split[1] : "main", id: action === "add" ? undefined : split[1]};
            case 3 :
                return {action, subView: split[1], id: split[2]};
            default:
                return {action: ""};
        }
    }, [param]);
};

const useActionWithIdNavigate = (): (item: ActionWithId) => void => {
    const navigate = useNavigate();
    const baseUrl = useBaseUrl();
    const current = useActionWithId();
    const {state, search} = useLocation();
    return useCallback((i: ActionWithId) => {
        if (i.action && !i.subView) {
            i.subView = "main";
        }
        if (i.action !== current.action || i.id !== current.id || i.subView !== current.subView) {
            const pathname = baseUrl + getPath(i);
            const shouldPush = window.innerWidth < BREAKPOINTS.tablet_landscape && ((current.action && !i.action) || current.action === "edit");
            navigate({search, pathname}, {state, replace: !shouldPush});
        }
    }, [baseUrl, current, navigate, search, state]);
};
