import { wrapLoadableHandler } from "../services/rest-interceptor";
import { takeLatest } from "redux-saga/effects";
import { RegistryActionCreators } from "../../store/registry/store";
import { RegistryService } from "../services/RegistryApi";

const findSinks = wrapLoadableHandler(RegistryActionCreators.findAllSinks, RegistryService.findSinks);
const findSink = wrapLoadableHandler(RegistryActionCreators.findSinkById, RegistryService.findSink);
const findCredits = wrapLoadableHandler(RegistryActionCreators.findAllCredits, RegistryService.findCredits);
const findCredit = wrapLoadableHandler(RegistryActionCreators.findCreditById, RegistryService.findCredit);
const findRetirements = wrapLoadableHandler(RegistryActionCreators.findAllRetirements, RegistryService.findRetirements);
const findRetirement = wrapLoadableHandler(RegistryActionCreators.findRetirementById, RegistryService.findRetirement);

export function* RegistrySaga() {
    yield takeLatest(findSinks.action, findSinks.handler);
    yield takeLatest(findSink.action, findSink.handler);
    yield takeLatest(findCredits.action, findCredits.handler);
    yield takeLatest(findCredit.action, findCredit.handler);
    yield takeLatest(findRetirements.action, findRetirements.handler);
    yield takeLatest(findRetirement.action, findRetirement.handler);
}
