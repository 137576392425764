import { useTranslation } from "react-i18next";
import React, { Fragment, ReactNode } from "react";

import "./DataViewItemComponent.scss";
import { Checkbox } from "primereact/checkbox";

interface TableEntryProps<T> {
    id?: string,
    selected: boolean,
    multiSelection: boolean,
    entry?: T,
    title?: ColumnDef<T>,
    subTitle?: ColumnDef<T>,
    columns: ColumnDef<T>[],
    onSelect?: () => void,
    onMultiSelect: (selected: boolean) => void,
    multiSelected: boolean
    multiSelectDisabled?: boolean;
}

export interface ColumnDef<T> {
    label?: string | ReactNode | ((rowData: T) => ReactNode);
    render: (rowData: T) => ReactNode;
    unit?: () => ReactNode;
}

export const DataViewItemComponent = <T extends any>(props: TableEntryProps<T>) => {
    const multiSelection = props.multiSelection;
    const {t} = useTranslation();
    const entry = props.entry;

    if (!entry) {
        return <Fragment/>;
    }
    const renderLabel = (label: string | ReactNode | ((rowData: T) => ReactNode)) => <Fragment>
        {typeof label === "string" && t(label)}
        {typeof label === "function" && label(entry)}
        {typeof label !== "string" && typeof label !== "function" && label}
    </Fragment>;

    return <div id={props.id}
                className={"component__data-view-item " + (props.selected ? "selected" : "")}
                onClick={multiSelection ? undefined : props.onSelect}
                key={props.id}>
        {(props.title || props.subTitle) && <div className="section__header">
            {props.title && <div className="element__title">
                {props.multiSelection && !props.multiSelectDisabled && <Checkbox checked={props.multiSelected} onChange={e => {
                    props.onMultiSelect(!!e.checked);
                }}/>}
                {props.title.render(entry)}
            </div>}
            {props.subTitle && <div className="element__subtitle">
                {props.subTitle.label && props.subTitle.render(entry) && <span>{renderLabel(props.subTitle.label)}: </span>}{props.subTitle.render(entry)}
            </div>
            }
        </div>}
        <div className="section__summary" onClick={multiSelection ? props.onSelect : undefined}>
            {props.columns.filter(c => !!c.render(entry)).map((def, i) =>
                <Fragment key={i}>
                    <div>{renderLabel(def.label)}</div>
                    <span>{def.render(entry)}<span className="element__unit">{def.unit?.()}</span></span>
                </Fragment>
            )}
        </div>
    </div>;
};
