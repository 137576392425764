import { findDOMNode } from "react-dom";
import { ReactInstance } from "react";

export const scrollIntoView = (searchContainer: ReactInstance | null | undefined, id: string, behavior: ScrollBehavior = "auto") => {
    const node = findDOMNode(searchContainer);
    if (node && node instanceof HTMLElement) {
        const byId = node.querySelector("#" + id);
        if (byId) {
            byId.scrollIntoView({behavior, block: "nearest", inline: "start"});
        }
    }
};
