import { useTableLoadActionWithDetail } from "../../components/master-detail/BasePagingMasterDetailView";
import { RootState } from "../../../store/root-reducer";
import { RegistryActionCreators } from "../../../store/registry/store";
import React from "react";
import "../sink/SinkView.scss";
import { timeTemplate } from "../../components/master-detail/Templates";
import {
    AmountFilterBox,
    DateFilterBox,
    FilterBarComponent,
    FilterComponentProps,
    RangeFilterBox,
    StringFilterBox
} from "../../components/master-detail/filter/FilterBarComponent";
import { PagingMasterDetailView } from "../../components/master-detail/PagingMasterDetailView";
import { CreditDetailView } from "./CreditDetailView";
import { useTranslation } from "react-i18next";
import { Persistence } from "../sink/SinkDetailView";
import { MatchMode } from "../../../middleware/model";
import { useTransactionId } from "../../../visuals/hooks";

const INITIAL_REQUEST = {debounce: 0, size: 10, page: 0, sortField: "issueTimestamp", sortOrder: -1};
const TABLE_DATA_SELECTOR = (state: RootState) => state.registry.findAllCredits;
const DETAIL_SELECTOR = (state: RootState) => state.registry.findCreditById;

export const CreditView = () => {
    const {t} = useTranslation();
    const detailActions = {view: RegistryActionCreators.findCreditById};
    const tableLoadAction = useTableLoadActionWithDetail(INITIAL_REQUEST, RegistryActionCreators.findAllCredits, TABLE_DATA_SELECTOR, DETAIL_SELECTOR);
    const transactionId = useTransactionId();

    return <PagingMasterDetailView
        detailActions={detailActions}
        defaultFilters={transactionId ? [{filterField: "transactionId", value: transactionId, matchMode: MatchMode.EQUALS}] : undefined}
        detailView={CreditDetailView}
        renderFilterBar={FILTER_BAR}
        sortOptions={SORT_OPTIONS}
        tableLoadAction={tableLoadAction}
        title={{render: credit => credit.externalId}}
        subTitle={{render: credit => t("Issue Timestamp") + ": " + timeTemplate(credit.issueTimestamp)}}
        columns={[
            {label: "Provider", render: credit => credit.provider.companyName},
            {label: "Amount", render: credit => credit.amountInTons},
            {label: "Retired Amount", render: credit => credit.retiredAmountInTons},
            {label: "Persistence", render: sink => <Persistence startYear={sink.vintage} persistenceInYears={sink.persistenceInYears}/>},
        ]}
    />;
};

const SORT_OPTIONS = [
    {label: "Issue Timestamp", value: "issueTimestamp"},
    {label: "Provider", value: "provider"},
    {label: "Persistence", value: "persistenceInYears"},
    {label: "Amount", value: "amountInTons"},
];

const FILTER_BAR = (p: FilterComponentProps) =>
    <FilterBarComponent
        filters={p.filters}
        onFilters={p.onFilters}
        filterBoxes={({
            "FULLTEXT": filterProps => <StringFilterBox {...filterProps} label={"Search"}/>,
            "issueTimestamp": filterProps => <DateFilterBox {...filterProps} label={"Issue Timestamp"}/>,
            "amountInTons": filterProps => <AmountFilterBox {...filterProps} label={"Amount"}/>,
            "persistenceInYears": filterProps => <RangeFilterBox {...filterProps} label={"Persistence"}/>,
        })}
    />;
