import React from "react";
import { SequestrationCurve } from "../../../store/registry/types";
import CurvesComponent, { CurveColor } from "./CurvesComponent";
import { numberTemplate } from "../master-detail/Templates";
import { useTranslation } from "react-i18next";

export interface LabeledSequestrationCurve extends SequestrationCurve {
    label: string;
    color: CurveColor;
    decimals?: number;
}

export interface InputProps {
    sequestrationCurves: LabeledSequestrationCurve[];
    title?: string;
}

export const SequestrationCurvesComponent = ({sequestrationCurves, title}: InputProps) => {
    const startYear = sequestrationCurves[0]?.startYear || -1;
    const endYear = sequestrationCurves[0]?.endYear || 0;
    const {t} = useTranslation();
    return <CurvesComponent
        startYear={startYear}
        endYear={endYear}
        curves={sequestrationCurves.map(it => ({label: getCurveLabel(t(it.label), it.amount, it.decimals), values: it.values, curveColor: it.color}))}
        title={title}
    />;
};

const getCurveLabel = (label: string, amount: number, decimals?: number) => {
    return label + ": " + numberTemplate("floor", amount, 1, decimals) + " tCO₂e";
};
