import React from "react";
import { Chart } from "primereact/chart";
import "./CurvesComponent.scss";
import { useTranslation } from "react-i18next";
import { toPadding } from "chart.js/helpers";

export enum CurveColor {
    PRIMARY = "#004c92",
    SECONDARY = "#E65621",
    TERTIARY = "#2ac471",
    BLACK = "#868585"
}

export interface Curve {
    label: string;
    values: number[];
    curveColor: CurveColor;
}

interface InputProps {
    curves: Curve[];
    startYear: number;
    endYear: number;
    title?: string;
}

// Chart Customization, based on default implementation in https://github.com/chartjs/Chart.js/blob/ba6b446b043f8806a2c9a7420516c9b0934b6c3d/src/plugins/plugin.legend.js
// Customizes the "lineDash" and "lineWidth" properties in order to improve the display of legend items.
const generateLabels = (chart: any) => {
    const datasets = chart.data.datasets;
    const {labels: {usePointStyle, pointStyle, textAlign, color}} = chart.legend.options;

    return chart._getSortedDatasetMetas().map((meta: any) => {
        const style = meta.controller.getStyle(usePointStyle ? 0 : undefined);
        const borderWidth = toPadding(style.borderWidth);

        return {
            text: datasets[meta.index].label,
            fillStyle: style.backgroundColor,
            fontColor: color,
            hidden: !meta.visible,
            lineCap: style.borderCapStyle,
            lineDash: [0,0],
            lineDashOffset: style.borderDashOffset,
            lineJoin: style.borderJoinStyle,
            lineWidth: (borderWidth.width + borderWidth.height) / 12,
            strokeStyle: style.borderColor,
            pointStyle: pointStyle || style.pointStyle,
            rotation: style.rotation,
            textAlign: textAlign || style.textAlign,
            borderRadius: 0,

            // Below is extra data used for toggling the datasets
            datasetIndex: meta.index
        };
    }, this);
};

const CurvesComponent = (props: InputProps) => {
    const {t} = useTranslation();

    const buildChartData = (curves: Curve[], startYear: number, endYear: number) =>
        ({
            labels: buildLabels(startYear, endYear),
            datasets:
                curves.map((curve, i) => ({
                    label: curve.label,
                    data: curve.values,
                    pointBorderWidth: 0.3,
                    borderColor: curve.curveColor,
                    backgroundColor: curve.curveColor + "11",
                    borderDash: [10, 30],
                    borderDashOffset: i * 10,
                    fill: true,
                }))
        });

    const buildChartOptions = (curves: Curve[], animationDuration: number) => ({
        plugins: {
            tooltip: {
                callbacks: () => ({title: (b: any) => b[0]?.parsed?.x})
            },
            legend: {
                labels: {
                    generateLabels: generateLabels,
                }
            }
        },
        animation: {
            duration: animationDuration,
        },
        elements: {
            point: {
                radius: 1
            }
        },
        responsive: true,
        hoverMode: "index",
        scales: {
            x: {
                type: "linear",
                grid: {
                    drawOnChartArea: false
                },
                ticks: {
                    minTicksLimit: 5,
                    maxTicksLimit: 7,
                    callback: (value: string) => value,
                }
            },
            y: {
                display: true,
                // type: "linear",
                beginAtZero: true,
                stacked: false,
                grid: {
                    drawOnChartArea: false
                },
                ticks: {
                    suggestedMax: 1,
                    maxTicksLimit: 3,
                },
                title: {
                    display: true,
                    text: t("Gross Amount (tCO₂e)")
                },
            }
        }
    });

    const buildLabels = (startYear: number, endYear: number) => {
        const labels: string[] = [];
        let year;
        for (year = startYear; year <= endYear; year += 1) {
            labels.push(String(year));
        }
        return labels;
    };

    return (<div className="component__sequestration-curve">
        <div className="element__subcaption">
            {t(props.title ? props.title : "Sequestration Curve")}
        </div>
        <Chart type="line"
               data={buildChartData(props.curves, props.startYear, props.endYear)}
               options={buildChartOptions(props.curves, 0)}/>
    </div>);
}

export default CurvesComponent;
