import React, { ReactNode, useCallback, useLayoutEffect, useRef, useState } from "react";
import "./BasicMasterDetailComponent.scss";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ActionWithId } from "../../ActionIdNavigator";
import MightyButton from "../mighty-button/MightyButton";
import { useTranslation } from "react-i18next";
import { Breakpoint, useCurrentWidth } from "react-socks";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { CollapseButtonLabelContext } from "../../Root";
import { ContextStateProvider } from "../context-state/ContextStateProvider";

export interface MasterDetailComponentProps<T extends WithId> {
    onSelectedId: (selectedId?: string) => void;
    action: ActionWithId;
    onMultiSelect?: (ids: T[]) => void;
    multiSelected?: T[];
    renderTableView: (tableProps: TableProps<T>) => ReactNode;
    renderDetailView: (action: ActionWithId) => ReactNode;
}

export interface TableProps<T> {
    onMultiSelect?: (ids: T[]) => void;
    multiSelected?: T[];
    onSelectedId?: (selectedId?: string) => void;
    selectedId?: string;
    autoSelect?: boolean;
}

export interface WithId {
    id: string | undefined;
}

export const BasicMasterDetailComponent = <T extends WithId>(
    {action, onSelectedId, multiSelected, onMultiSelect, renderDetailView, renderTableView}: MasterDetailComponentProps<T>
) => {
    const {t} = useTranslation();
    const masterDivRef = useRef<HTMLDivElement>(null);
    const [collapseMasterButtonLabels, setCollapseMasterButtonLabels] = useState<boolean>(false);
    const reevaluateMasterColumnWidth = useCallback(() => {
        if (masterDivRef.current?.clientWidth) {
            setCollapseMasterButtonLabels(masterDivRef.current?.clientWidth < 650);
        }
    }, []);
    const windowWidth = useCurrentWidth();
    useLayoutEffect(reevaluateMasterColumnWidth, [reevaluateMasterColumnWidth, windowWidth]);
    return <ContextStateProvider>
        <div className="component__master-detail">
            <Breakpoint className={"breakpoint view__shared"} tablet_landscape up>
                <Splitter gutterSize={10} layout="horizontal" onResizeEnd={reevaluateMasterColumnWidth}>
                    <SplitterPanel size={80} minSize={30}>
                        <CollapseButtonLabelContext.Provider value={collapseMasterButtonLabels}>
                            <div className={"section__master " + (!!onSelectedId ? "selectable" : "")} ref={masterDivRef}>
                                {renderTableView({
                                    autoSelect: action.action === "view" || action.action === "",
                                    onSelectedId,
                                    selectedId: action.id,
                                    multiSelected: multiSelected,
                                    onMultiSelect: onMultiSelect
                                })}
                            </div>
                        </CollapseButtonLabelContext.Provider>
                    </SplitterPanel>

                    <SplitterPanel size={120} minSize={30}>
                        <div className="section__detail">
                            <div className="section__detail-content">
                                {renderDetailView(action)}
                            </div>
                        </div>
                    </SplitterPanel>
                </Splitter>

            </Breakpoint>
            <Breakpoint className="breakpoint view__single" tablet down>
                <SwitchTransition mode="out-in">
                    <CSSTransition timeout={300} classNames="view-switch" key={action.action + action.id}>
                        {action.action === "" ?
                            <div className={"section__master " + (!!onSelectedId ? "selectable" : "")} ref={masterDivRef}>
                                <CollapseButtonLabelContext.Provider value={collapseMasterButtonLabels}>
                                    {renderTableView({
                                        autoSelect: false,
                                        onSelectedId,
                                        selectedId: action.id,
                                        multiSelected,
                                        onMultiSelect
                                    })}
                                </CollapseButtonLabelContext.Provider>
                            </div> :
                            <div className="section__detail">
                                <div className={"element__navigation"}>
                                    <MightyButton onButtonClick={() => onSelectedId()} icon="pi-chevron-left" class="icon-text" label={t("Back")}/>
                                </div>
                                <div className="section__detail-content">
                                    {renderDetailView(action)}
                                </div>
                            </div>}
                    </CSSTransition>
                </SwitchTransition>
            </Breakpoint>
        </div>
    </ContextStateProvider>;
};
