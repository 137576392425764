import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { isLanguageSupported } from "./i18n/i18n";
import { addLocale, locale } from "primereact/api";
import CalendarLocales from "./i18n/CalendarLocales.json";

/*
We try to determine the language from query params or browser language detector (in this order)
 */

addLocale("de", CalendarLocales["de"]);
addLocale("en", CalendarLocales["en"]);

export const useLanguageHandler = () => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        i18n.on("languageChanged", (lng: string) => {
            moment.locale(lng);
            locale(lng);
        });
        // trigger a language change to synchronize with moment
        void i18n.changeLanguage(i18n.language);
        return () => i18n.off("languageChanged");
    }, [i18n]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const fromParams = urlParams.get("lang");
        if (fromParams && isLanguageSupported(fromParams)) {
            void i18n.changeLanguage(fromParams);
        }
        if (!isLanguageSupported(i18n.language)) {
            void i18n.changeLanguage(i18n.options.fallbackLng as string);
        }
        if (fromParams) {
            urlParams.delete("lang");
            navigate({
                pathname: location.pathname,
                search: "?" + urlParams.toString()
            }, {replace: true});
        }
    }, [navigate, i18n, location.search, location.pathname, i18n.language]);
};
