import React, { Fragment } from "react";
import { Action } from "typesafe-actions";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import "./MightyButton.scss";
import { useAsyncWatcher } from "../async-watcher/UseAsyncWatcher";
import { convertToArray } from "../../../utility-functions";

/*
    Generic Button component which can visually either display
    - a button with a text label and the style of either primary, secondary or tertiary button
    - an icon button
    Further, it can execute asynchronous actions, showing a loading state meanwhile.
 */

interface InputProps {
    label?: string;                                 // The text to show on the button. If not provided, an Icon button is rendered.
    icon?: string;                                  // The icon to show either alone or together with the label. Either label or icon must be provided.
    iconLabel?: string;
    class?: "primary" | "secondary" | "tertiary" | "icon-text" | "warning";
    title?: string;

    // The visual class to display the button
    className?: string;                             // Any additional class to add to the element
    disabled?: boolean;                             // Whether to disable the button
    asyncAction?: Action | Action[];                // The asynchronous action that will be executed once the user clicked the button.
    onButtonClick?: (e?: any) => void;                     // The handler that is triggered once the user clicked the button (& all async actions have executed, if necessary)
}

const MightyButton = (props: InputProps) => {
    const watcher = useAsyncWatcher(props.asyncAction, { onSuccess: () => props.onButtonClick?.() });
    const dispatch = useDispatch();
    const getIcon = () => watcher?.ongoing ? "pi pi-spinner pi-spin" : (props.icon && ("pi " + props.icon));

    const onButtonClick = (e?: any) => {
        if (watcher?.ongoing || props.disabled) {
            return;
        }

        if (props.asyncAction) {
            convertToArray(props.asyncAction).forEach(dispatch);
        } else {
            props.onButtonClick?.(e);
        }
    };

    return <Fragment>
        {!props.label ?
            <i className={"component__mighty-button icon-only " + (props.className || "") + " " + ((watcher?.ongoing || props.disabled) ? "disabled " : " ") + getIcon()}
               onClick={onButtonClick} title={props.title}>
                { props.iconLabel && <span className={"component__mighty-button icon-label"}>{props.iconLabel}</span> }
            </i>
            : <Button className={"component__mighty-button with-text p-button p-button-" + (props.class || "primary") + " " + (props.className || "")}
                      icon={getIcon()}
                      title={props.title}
                      disabled={watcher?.ongoing || props.disabled}
                      label={props.label}
                      onClick={onButtonClick}
                      type="button"
            />
        }
    </Fragment>;
}

export default MightyButton;
